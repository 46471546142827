import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ConsultantOnboardingPersonal = () => {
  const [formData, setFormData] = useState({
    name: '',
    about: '',
    phone: '',
    city: '',
    state: '',
  });
  const [photo, setPhoto] = useState(null);
  const [photoName, setPhotoName] = useState('');
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Personal' : 'Consultant Onboarding - Personal',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserData();
  }, [isEditMode]);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('name, about, phone, city, state, photo')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setFormData({
        name: data.name || '',
        about: data.about || '',
        phone: formatPhoneNumber(data.phone ? data.phone.toString() : '') || '',
        city: data.city || '',
        state: data.state || '',
      });
      setPhoto(data.photo || null);
      setPhotoName(data.photo ? data.photo.split('/').pop() : '');
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };
  
  useEffect(() => {
    // Ensure that all formData fields are strings before trimming
    const isFormValid = (formData.name || '').trim() !== '' &&
                        String(formData.phone || '').trim() !== '' &&
                        (formData.city || '').trim() !== '' &&
                        (formData.state || '').trim() !== '';
  
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'phone') {
      const formattedPhone = formatPhoneNumber(value);
      setFormData(prevData => ({
        ...prevData,
        [name]: formattedPhone
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const unformatPhoneNumber = (value) => {
    return value.replace(/\D/g, '');
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        alert('Please upload a valid image file (JPEG, PNG, or GIF)');
        return;
      }
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        alert('File size should not exceed 10MB');
        return;
      }
      setPhoto(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const phoneNumber = unformatPhoneNumber(formData.phone);
      const phoneNumberAsNumber = phoneNumber ? parseInt(phoneNumber, 10) : null;  

      let photoUrl = photo;
      if (photo instanceof File) {
        const fileExt = photo.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;

        const { data, error } = await supabase.storage
          .from('photos')
          .upload(filePath, photo);
        
        if (error) throw error;
        
        const { data: { publicUrl }, error: urlError } = supabase.storage
          .from('photos')
          .getPublicUrl(filePath);

        if (urlError) throw urlError;
        photoUrl = publicUrl;
      }

      const { error } = await supabase
        .from('users')
        .update({
          name: formData.name,
          about: formData.about,
          phone: phoneNumberAsNumber,
          city: formData.city,
          state: formData.state,
          photo: photoUrl
        })
        .eq('user_id', user.id);

      if (error) throw error;

      if (isEditMode) {
        navigate('/consultant-edit-profile-professional', { state: { isEditMode: true } });
      } else {
        navigate('/consultant-onboarding-professional');
      }
    } catch (error) {
      console.error('Error updating user info:', error.message);
      // Handle error (show error message to user)
    }
  };

  return (
    <>
    <Helmet>
      <title>{isEditMode ? 'Edit Profile - Personal Information' : 'Consultant Onboarding - Personal Information'} | fetchConsultant</title>
      <meta name="description" content={isEditMode ? "Update your personal profile as a healthcare IT consultant on fetchConsultant." : "Complete your personal profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities."} />
      <meta name="description" content="Complete your personal profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Consultant Onboarding - Personal Information | fetchConsultant" />
      <meta property="og:description" content="Complete your personal profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://app.fetchconsultant.com/consultant-onboarding-personal" />
      <meta property="og:image" content="https://app.fetchconsultant.com/path-to-image.jpg" />
    </Helmet>
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="fetch-header-wrapper">
        <div className="fetch-header-content">
          <div className="fetch-header-top">
            <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
            <h1 className="fetch-page-title">{isEditMode ? 'Edit Profile' : 'Consultant Onboarding'}</h1>
            <div className="fetch-back-button-placeholder"></div>
          </div>
          {!isEditMode && (
            <div className="fetch-progress">
              <p className="fetch-secondary-text">Step 1 of 2</p>
              <div className="fetch-progress-bar">
                <div className="fetch-progress-bar-fill" style={{width: '50%'}}></div>
              </div>
            </div>
          )}
        </div>
      </header>
      <main className="fetch-container">
        <div className="fetch-card">
          <form onSubmit={handleSubmit} className="fetch-form">
            <div>
              <label htmlFor="name" className="fetch-label">Name<span className="fetch-required">*</span></label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                className="fetch-input"
              />
            </div>
            <div>
              <label htmlFor="about" className="fetch-label">About</label>
              <textarea
                id="about"
                name="about"
                value={formData.about}
                onChange={handleInputChange}
                rows="4"
                className="fetch-textarea"
              ></textarea>
            </div>
            <div>
              <label htmlFor="photo" className="fetch-label">
                {photoName ? 'Update Photo' : 'Upload Photo'}
              </label>
              <div className="mt-1 flex items-center">
                <input
                  type="file"
                  id="photo"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  className="hidden"
                />
                <label
                  htmlFor="photo"
                  className="file-input-button"
                >
                  <Upload className="w-5 h-5 mr-2" />
                  {photoName ? 'Update Photo' : 'Upload Photo'}
                </label>
                {(photo instanceof File ? photo.name : photoName) && (
                  <span className="ml-3 text-sm text-gray-600">
                    {photo instanceof File ? photo.name : photoName}
                  </span>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="phone" className="fetch-label">Phone Number<span className="fetch-required">*</span></label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="(###) ###-####"
                maxLength="14"
                required
                className="fetch-input"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="city" className="fetch-label">City<span className="fetch-required">*</span></label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                  className="fetch-input"
                />
              </div>
              <div>
                <label htmlFor="state" className="fetch-label">State<span className="fetch-required">*</span></label>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                  className="fetch-select"
                >
                  <option value="">Select State</option>
                  {/* Add options for all 50 states */}
                  <option value="TX">Texas</option>
                  {/* ... other states ... */}
                </select>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!formValid}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  </>
);
}

export default ConsultantOnboardingPersonal;