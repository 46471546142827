import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';

const ClientDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobs();
    fetchUser();
    fetchUnreadNotifications();
  }, []);

  const fetchJobs = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const { data, error } = await supabase
        .from('jobs')
        .select(`
          *,
          user_jobs (count)
        `)
        .eq('created_by', user.id)
        .order('created_at', { ascending: false });
  
      if (error) throw error;
      setJobs(data.map(job => ({
        ...job,
        applicant_count: job.user_jobs[0]?.count || 0
      })));
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
    }
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEditJob = (e, jobId) => {
    e.stopPropagation();
    navigate(`/edit-job/${jobId}`);
  };

  const handleJobClick = (jobId) => {
    navigate(`/applicants/${jobId}`);
  };

  const createDraftJob = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('employer')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      if (!userData.employer) {
        throw new Error("User's employer not set");
      }

      const { data, error } = await supabase
        .from('jobs')
        .insert([
          { 
            created_by: user.id,
            client_id: userData.employer,
            status: 'Draft',
            title: 'Untitled Job',
            description: '',
            duration: null,
            extension_possibility: null,
            travel: null,
            ideal_start_date: null,
            terms_and_conditions: false
          }
        ])
        .select()
        .single();

      if (error) throw error;
      
      navigate(`/edit-job/${data.job_id}`);
    } catch (error) {
      console.error('Error creating draft job:', error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Client Dashboard | fetchConsultant</title>
        <meta name="description" content="View and manage your job postings, applicants, and referrals on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="dashboard" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Jobs</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
              <button 
                onClick={createDraftJob} 
                className="bg-indigo-600 text-white px-4 py-2 rounded-full text-sm font-bold"
              >
                Post Job
              </button>
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {jobs.map(job => (
              <div key={job.job_id} className="fetch-job-card cursor-pointer" onClick={() => handleJobClick(job.job_id)}>
                <h2 className="text-xl font-bold mb-2">{job.title}</h2>
                <p className="text-gray-600 mb-2">Created: {new Date(job.created_at).toLocaleDateString()}</p>
                <p className="text-gray-600 mb-2">Status: {job.status}</p>
                <p className="text-gray-600 mb-4">Applicants: {job.applicant_count}</p>
                <button 
                  onClick={(e) => handleEditJob(e, job.job_id)}
                  className="text-indigo-600 hover:text-indigo-800"
                >
                  Edit
                </button>
              </div>
            ))}
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientDashboard;