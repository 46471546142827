import { getAnalytics, logEvent } from "firebase/analytics";
import { Star } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const SubmitReview = () => {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pendingReviews, setPendingReviews] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Submit Review',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      setIsAuthenticated(true);
      setCurrentUserId(user.id);
      fetchPendingReviews(user.id);
    } else {
      navigate(`/review-benefits/${token}`);
    }
  };

  const fetchPendingReviews = async (userId) => {
    try {
      let query = supabase
        .from('review_tokens')
        .select(`
          *,
          consultant:users!review_tokens_consultant_id_fkey(name, user_id)
        `)
        .is('used_at', null);

      if (token) {
        query = query.eq('token', token);
      } else {
        query = query.eq('reviewer_id', userId);
      }

      const { data, error } = await query;

      if (error) throw error;

      // Filter out self-reviews
      const filteredReviews = data.filter(review => review.consultant.user_id !== userId);

      setPendingReviews(filteredReviews);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching pending reviews:', error);
      setMessage('Error fetching pending reviews');
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e, reviewToken) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (currentUserId === reviewToken.consultant.user_id) {
        throw new Error("You cannot review yourself");
      }

      const { data: existingReview, error: checkError } = await supabase
        .from('reviews')
        .select('id')
        .eq('consultant_id', reviewToken.consultant_id)
        .eq('reviewer_id', currentUserId)
        .single();

      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }

      if (existingReview) {
        throw new Error("You have already submitted a review for this consultant");
      }

      const { error: reviewError } = await supabase
        .from('reviews')
        .insert({
          consultant_id: reviewToken.consultant_id,
          reviewer_id: currentUserId,
          rating,
          review_text: reviewText,
          review_type: reviewToken.review_type,
        });

      if (reviewError) throw reviewError;

      // Mark the review token as used
      await supabase
        .from('review_tokens')
        .update({ used_at: new Date().toISOString() })
        .eq('id', reviewToken.id);

      setMessage('Review submitted successfully!');
      setRating(0);
      setReviewText('');
      fetchPendingReviews(currentUserId);
    } catch (error) {
      console.error('Error submitting review:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthenticated) {
    return null; // The user will be redirected in checkAuthStatus
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Submit Review | fetchConsultant</title>
        <meta name="description" content="Submit a review for a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <h1 className="text-2xl font-bold mb-4">Pending Reviews</h1>
          {pendingReviews.length === 0 ? (
            <p>No pending reviews at this time.</p>
          ) : (
            pendingReviews.map((reviewToken) => (
              <div key={reviewToken.id} className="mb-8">
                <h2 className="text-xl font-semibold mb-2">Review for {reviewToken.consultant.name}</h2>
                <p className="mb-4">Review type: {reviewToken.review_type}</p>
                <form onSubmit={(e) => handleSubmit(e, reviewToken)} className="space-y-4">
                  <div>
                    <label className="block mb-2">Rating</label>
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                          key={star}
                          size={24}
                          onClick={() => setRating(star)}
                          fill={star <= rating ? 'gold' : 'none'}
                          stroke={star <= rating ? 'gold' : 'currentColor'}
                          className="cursor-pointer"
                        />
                      ))}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="reviewText" className="block mb-2">Review</label>
                    <textarea
                      id="reviewText"
                      value={reviewText}
                      onChange={(e) => setReviewText(e.target.value)}
                      className="w-full p-2 border rounded"
                      rows="4"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700">
                    Submit Review
                  </button>
                </form>
              </div>
            ))
          )}
          {message && <p className="mt-4 text-center text-green-600">{message}</p>}
        </div>
      </div>
    </>
  );
};

export default SubmitReview;