import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Calendar, CheckCircle, Eye, Menu, MessageSquare, Video, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import Spinner from './Spinner'; // Assuming you have a Spinner component

const Interviews = () => {
  const [interviews, setInterviews] = useState({ upcoming: [], past: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Interviews',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserTypeAndInterviews();
  }, []);

  const fetchUserTypeAndInterviews = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      setUser(user);

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('user_type_id')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      setUserType(userData.user_type_id);

      const isConsultant = userData.user_type_id === 2;
      const isClient = userData.user_type_id === 4;

      if (isConsultant || isClient) {
        await fetchInterviews(user.id, isConsultant);
      } else {
        throw new Error("Invalid user type");
      }
    } catch (error) {
      console.error('Error fetching user type and interviews:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInterviews = async (userId, isConsultant) => {
    try {
      let query = supabase
        .from('interviews')
        .select(`
          *,
          user_job:user_jobs (
            user_id,
            job:jobs (
              title,
              client:clients (name),
              created_by
            ),
            consultant:users!user_jobs_user_id_fkey (name)
          )
        `)
        .order('interview_time', { ascending: true });

      if (isConsultant) {
        query = query.eq('user_job.user_id', userId);
      } else {
        query = query.eq('user_job.job.created_by', userId);
      }

      const { data, error } = await query;

      if (error) throw error;

      const now = new Date();
      const upcoming = data.filter(interview => new Date(interview.interview_time) > now);
      const past = data.filter(interview => new Date(interview.interview_time) <= now);

      setInterviews({ upcoming, past });
    } catch (error) {
      console.error('Error fetching interviews:', error.message);
    }
  };

  const renderInterviewList = (interviews, isPast = false) => {
    return (
      <div>
        <h2 className="text-xl font-bold mb-4">{isPast ? 'Past Interviews' : 'Upcoming Interviews'}</h2>
        {interviews.length === 0 ? (
          <p>No {isPast ? 'past' : 'upcoming'} interviews.</p>
        ) : (
          <ul className="space-y-4">
            {interviews.map((interview) => (
              <li key={interview.id} className="fetch-job-card bg-white p-4 rounded-lg shadow">
                <h3 className="font-semibold">{interview.user_job.job.title}</h3>
                <p>{userType === 2 ? `Client: ${interview.user_job.job.client.name}` : `Consultant: ${interview.user_job.consultant.name}`}</p>
                <p>Date: {formatDateTime(interview.interview_time)}</p>
                <p>Type: {interview.is_second_interview ? 'Second Interview' : 'First Interview'}</p>
                <div className="mt-4 space-x-2">
                  {!isPast && interview.zoom_meeting_link && (
                    <button
                      onClick={() => launchZoomMeeting(interview.zoom_meeting_link)}
                      className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
                    >
                      <Video className="mr-2" size={20} />
                      Join Zoom Meeting
                    </button>
                  )}
                  {isPast && (
                    <>
                      <button
                        onClick={() => navigate(`/interview-feedback/${interview.user_job_id}`)}
                        className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
                      >
                        <MessageSquare className="mr-2" size={20} />
                        Provide Feedback
                      </button>
                      <button
                        onClick={() => navigate(`/view-feedback/${interview.user_job_id}`)}
                        className="bg-indigo-500 text-white px-4 py-2 rounded flex items-center"
                      >
                        <Eye className="mr-2" size={20} />
                        View Feedback
                      </button>
                      {userType === 4 && !interview.is_second_interview && (
                        <>
                          <button
                            onClick={() => navigate(`/request-second-interview/${interview.user_job_id}`)}
                            className="bg-yellow-500 text-white px-4 py-2 rounded flex items-center"
                          >
                            <Calendar className="mr-2" size={20} />
                            Request Second Interview
                          </button>
                          <button
                            onClick={() => handleRejectApplicant(interview.user_job_id)}
                            className="bg-red-500 text-white px-4 py-2 rounded flex items-center"
                          >
                            <X className="mr-2" size={20} />
                            Reject Applicant
                          </button>
                          <button
                            onClick={() => handleOfferContract(interview.user_job_id)}
                            className="bg-indigo-500 text-white px-4 py-2 rounded flex items-center"
                          >
                            <CheckCircle className="mr-2" size={20} />
                            Offer Contract
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  const launchZoomMeeting = (zoomLink) => {
    window.open(zoomLink, '_blank');
  };

  const handleRejectApplicant = async (userJobId) => {
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({ status: 'Rejected' })
        .eq('user_job_id', userJobId);

      if (error) throw error;

      fetchUserTypeAndInterviews();
      alert('Applicant has been rejected.');
    } catch (error) {
      console.error('Error rejecting applicant:', error.message);
      alert('Failed to reject applicant. Please try again.');
    }
  };

  const handleOfferContract = async (userJobId) => {
    try {
      const { error: updateError } = await supabase
        .from('user_jobs')
        .update({ status: 'Contract Offered' })
        .eq('user_job_id', userJobId);

      if (updateError) throw updateError;

      const { error: insertError } = await supabase
        .from('engagements')
        .insert({
          user_job_id: userJobId,
          status: 'Pending Acceptance',
        });

      if (insertError) throw insertError;

      fetchUserTypeAndInterviews();
      alert('Contract has been offered to the consultant.');
    } catch (error) {
      console.error('Error offering contract:', error.message);
      alert('Failed to offer contract. Please try again.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Interviews | fetchConsultant</title>
        <meta name="description" content="View and manage your interviews on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="interviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Interviews</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
              </button>
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <PullToRefresh
              onRefresh={fetchUserTypeAndInterviews}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              <div className="max-w-2xl mx-auto">
                {renderInterviewList(interviews.upcoming)}
              </div>
              <div className="max-w-2xl mx-auto mt-8">
                {renderInterviewList(interviews.past, true)}
              </div>
            </PullToRefresh>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Interviews;