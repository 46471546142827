import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu, Plus, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';


const RequestSecondInterview = () => {
  const [interviewSlots, setInterviewSlots] = useState(['']);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const { userJobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Request Second Interview',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchInterviewDetails();
    fetchUnreadNotifications();
  }, [userJobId]);

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const fetchInterviewDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          job:jobs(title),
          consultant:users!user_jobs_user_id_fkey(name)
        `)
        .eq('user_job_id', userJobId)
        .single();

      if (error) throw error;
      setInterviewDetails(data);
    } catch (error) {
      console.error('Error fetching interview details:', error.message);
    }
  };

  const handleAddSlot = () => {
    if (interviewSlots.length < 3) {
      setInterviewSlots([...interviewSlots, '']);
    }
  };

  const handleRemoveSlot = (index) => {
    const newSlots = interviewSlots.filter((_, i) => i !== index);
    setInterviewSlots(newSlots);
  };

  const handleSlotChange = (index, value) => {
    const newSlots = [...interviewSlots];
    newSlots[index] = value;
    setInterviewSlots(newSlots);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({
          status: 'Second Interview Requested',
          second_interview_slots: interviewSlots,
        })
        .eq('user_job_id', userJobId);

      if (error) throw error;

      const analytics = getAnalytics();
      logEvent(analytics, 'second_interview_requested', {
        user_job_id: userJobId,
      });

      alert('Second interview request sent successfully!');
      navigate('/interviews');
    } catch (error) {
      console.error('Error requesting second interview:', error.message);
      alert('Failed to request second interview. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Request Second Interview | fetchConsultant</title>
        <meta name="description" content="Request a second interview with a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Request Second Interview</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <main className="flex-1 overflow-y-auto p-4">
            {interviewDetails && (
              <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-bold mb-4">{interviewDetails.job.title}</h2>
                <p className="mb-6">Consultant: {interviewDetails.consultant.name}</p>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block mb-2 font-semibold">Proposed Interview Slots</label>
                    {interviewSlots.map((slot, index) => (
                      <div key={index} className="flex items-center space-x-2 mb-2">
                        <input
                          type="datetime-local"
                          value={slot}
                          onChange={(e) => handleSlotChange(index, e.target.value)}
                          className="flex-grow p-2 border rounded"
                          required
                        />
                        {index > 0 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveSlot(index)}
                            className="text-red-500"
                          >
                            <X size={20} />
                          </button>
                        )}
                      </div>
                    ))}
                    {interviewSlots.length < 3 && (
                      <button
                        type="button"
                        onClick={handleAddSlot}
                        className="flex items-center text-indigo-600 mt-2"
                      >
                        <Plus size={20} className="mr-1" />
                        Add Another Slot
                      </button>
                    )}
                  </div>
                  <button type="submit" className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300">
                    Send Request
                  </button>
                </form>
              </div>
            )}
          </main>
          </div>
      </div>
    </>
  );
};

export default RequestSecondInterview;