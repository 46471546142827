import { getAnalytics, logEvent } from "firebase/analytics";
import { Briefcase, Calendar, HelpCircle, LogOut, Mail, Settings, X } from 'lucide-react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientHamburger = ({ user, currentPage, isOpen, onClose }) => {
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      logEvent(analytics, 'logout', {
        method: 'hamburger_menu',
        user_type: 'Verified Client'
      });
      navigate('/auth');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const handleSupport = () => {
    window.location.href = 'mailto:support@fetchconsultant.com';
    logEvent(analytics, 'support_email_click', {
      method: 'hamburger_menu',
      user_type: 'client'
    });
  };

  const menuItems = [
    { icon: Briefcase, text: 'Dashboard', link: '/client-dashboard' },
    { icon: Calendar, text: 'Interviews', link: '/interviews' },
    { icon: Briefcase, text: 'Engagements', link: '/engagement-management' },
    { icon: Settings, text: 'Approve Hours', link: '/client-approve-hours' },
    { icon: Settings, text: 'Settings', link: '/client-settings' },
  ];

  return (
    <div className={`bg-white w-64 h-full shadow-lg flex flex-col fixed top-0 left-0 z-50 transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
      <div className="p-4 flex-grow">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <img src="/images/fetch-logo.png" alt="Fetch Logo" className="h-8 mr-2" />
            <h2 className="text-lg font-semibold">fetchConsultant</h2>
          </div>
          <button onClick={onClose} className="md:hidden">
            <X size={24} />
          </button>
        </div>
        <nav>
          <ul className="space-y-2">
            {menuItems.map((item) => (
              <li key={item.text}>
                <Link
                  to={item.link}
                  className={`flex items-center py-2 px-4 rounded-lg ${
                    currentPage === item.text.toLowerCase()
                      ? 'bg-red-100 text-black font-semibold'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                  onClick={() => onClose()}
                >
                  <item.icon size={20} className="mr-3" />
                  {item.text}
                  {currentPage === item.text.toLowerCase() && (
                    <span className="ml-auto w-2 h-2 bg-red-500 rounded-full"></span>
                  )}
                </Link>
              </li>
            ))}
            <li>
              <button
                onClick={() => { handleSupport(); onClose(); }}
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                <Mail size={20} className="mr-3" />
                Support
              </button>
            </li>
            <li>
              <a
                href="https://www.fetchconsultant.com/faq"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100"
                onClick={() => onClose()}
              >
                <HelpCircle size={20} className="mr-3" />
                F.A.Q.
              </a>
            </li>
            <li>
              <button
                onClick={() => { handleLogout(); onClose(); }}
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                <LogOut size={20} className="mr-3" />
                Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="p-4 text-sm text-gray-500">
        Version 1.0.0
      </div>
    </div>
  );
};

export default ClientHamburger;