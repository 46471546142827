import React, { useEffect, useState } from 'react';
import { checkNotificationPermission, requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';

const NotificationPermissionWarning = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: settings } = await supabase
        .from('notification_settings')
        .select('*')
        .eq('user_id', user.id);

      const pushEnabled = settings.some(setting => setting.notification_options.push);
      const permissionGranted = checkNotificationPermission();

      setShowWarning(pushEnabled && !permissionGranted);
    }
  };

  const handleEnableNotifications = async () => {
      const granted = await requestNotificationPermission();
      if (granted) {
        setShowWarning(false);
    }
  };

  if (!showWarning) return null;

  return (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
      <p className="font-bold">Notification Warning</p>
      <p>Push notifications are enabled in your settings, but browser permissions are not granted.</p>
      <button
        onClick={handleEnableNotifications}
        className="mt-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
      >
        {isIOS ? "How to Enable Notifications" : "Enable Notifications"}
      </button>
    </div>
  );
};

export default NotificationPermissionWarning;