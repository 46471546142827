import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';

const SolicitReview = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [reviewType, setReviewType] = useState('peer');
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Solicit Review',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      if (!email && !phone) {
        throw new Error("Please provide either an email or a phone number");
      }

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      console.log('Sending review request with data:', {
        reviewerEmail: email,
        reviewerPhone: phone,
        reviewType,
        consultantId: user.id
      });

      const { data, error } = await supabase.functions.invoke('send-review-request', {
        body: {
          reviewerEmail: email,
          reviewerPhone: phone,
          reviewType,
          consultantId: user.id
        }
      });

      if (error) throw error;

      console.log('Review request response:', data);

      const analytics = getAnalytics();
      logEvent(analytics, 'review_solicited', {
        review_type: reviewType,
      });

      setShowPopup(true);
    } catch (error) {
      console.error('Error soliciting review:', error);
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestAnother = () => {
    setEmail('');
    setPhone('');
    setReviewType('peer');
    setShowPopup(false);
    setError(null);
  };

  return (
    <>
      <Helmet>
        <title>Solicit Review | fetchConsultant</title>
        <meta name="description" content="How good of an Epic consultant are you? Solicit a review from a manager or peer on fetchConsultant." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Solicit Review | fetchConsultant" />
        <meta property="og:description" content="How good of an Epic consultant are you? Solicit a review from a manager or peer on fetchConsultant." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.fetchconsultant.com/solicit-review" />
        <meta property="og:image" content="https://app.fetchconsultant.com/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="solicit-review" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Solicit Review</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="email" className="fetch-label">Reviewer's Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="fetch-input"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="fetch-label">Reviewer's Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="(###) ###-####"
                    className="fetch-input"
                  />
                </div>
                <div>
                  <label htmlFor="reviewType" className="fetch-label">Review Type</label>
                  <select
                    id="reviewType"
                    value={reviewType}
                    onChange={(e) => setReviewType(e.target.value)}
                    className="fetch-select"
                  >
                    <option value="peer">Peer</option>
                    <option value="manager">Manager</option>
                  </select>
                </div>
                <button type="submit" className="fetch-button" disabled={isLoading || (!email && !phone)}>
                  {isLoading ? 'Sending...' : 'Send Review Request'}
                </button>
              </form>
            </div>
          </main>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Review Request Sent</h2>
            <p className="mb-4">
              Your review request has been submitted successfully. The reviewer will receive a notification with instructions to submit their review.
            </p>
            <div className="flex justify-between">
              <button 
                onClick={handleRequestAnother} 
                className="fetch-button bg-indigo-600 text-white"
              >
                Request Another Review
              </button>
              <button 
                onClick={() => navigate('/consultant-dashboard')} 
                className="fetch-button bg-gray-300 text-gray-800"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SolicitReview;