import { supabase } from '../supabaseClient';

export const saveFcmToken = async (userId, token) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .update({ fcm_token: token })
      .eq('user_id', userId);

    if (error) throw error;
    console.log('FCM token saved successfully');
    return data;
  } catch (error) {
    console.error('Error saving FCM token:', error);
    throw error;
  }
};