// src/components/RoleBasedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const RoleBasedRoute = ({ component: Component, allowedTypes, fallbackPath }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkUserType = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('user_type_id')
          .eq('user_id', user.id)
          .single();

        if (!error) {
          setUserType(data.user_type_id);
        }
      }
      setIsLoading(false);
    };

    checkUserType();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  if (!userType || (allowedTypes.indexOf(userType) === -1 && userType !== 5 && userType !== 6)) {
    return <Navigate to={fallbackPath} state={{ from: location }} replace />;
  }

  return <Component />;
};

export default RoleBasedRoute;