import { supabase } from '../supabaseClient';

export const createZoomMeeting = async (topic, startTime, duration) => {
  try {
    const { data, error } = await supabase.functions.invoke('create-zoom-meeting', {
      body: JSON.stringify({ topic, startTime, duration }),
    });

    if (error) throw error;
    console.log('Zoom meeting created:', data);
    return data;
  } catch (error) {
    console.error('Error creating Zoom meeting:', error);
    throw error;
  }
};