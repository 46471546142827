import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, ChevronLeft, Download, Menu, PlayCircle, Star } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';

const ApplicantDetails = () => {
  const [applicant, setApplicant] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userJobId } = useParams();
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);

  const fetchReviews = useCallback(async (consultantId) => {
    try {
      const { data, error } = await supabase
        .from('reviews')
        .select('*, reviewer:reviewer_id(name)')
        .eq('consultant_id', consultantId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setReviews(data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  }, []);

  const fetchApplicantDetails = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          users!user_jobs_user_id_fkey (
            user_id,
            name,
            about,
            photo,
            city,
            state,
            linkedin_url,
            former_epic,
            average_rating,
            resume,
            user_epic_certifications (
              epic_applications (name)
            )
          ),
          job:job_id (
            title,
            video_questions
          ),
          referrer:referred_by (
            user_id,
            name
          )
        `)
        .eq('user_job_id', userJobId)
        .single();
  
      if (error) throw error;
  
      console.log('Fetched applicant data:', data);
  
      setApplicant(data);
      if (data) {
        updateLastViewedByClient(data.user_job_id);
        fetchReviews(data.users.user_id);
      }
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    } finally {
      setLoading(false);
    }
  }, [userJobId, fetchReviews]);

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Applicant Details',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchApplicantDetails();
    fetchUnreadNotifications();
    fetchUser();
  }, [fetchApplicantDetails]);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const updateLastViewedByClient = async (userJobId) => {
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({ last_viewed_by_client: new Date().toISOString() })
        .eq('user_job_id', userJobId);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating last viewed by client:', error);
    }
  };

  const handleReject = async () => {
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({ status: 'Rejected' })
        .eq('user_job_id', userJobId);

      if (error) throw error;
      navigate(-1);
    } catch (error) {
      console.error('Error rejecting applicant:', error);
    }
  };

  const handleInterview = async () => {
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({ status: 'Interview Requested' })
        .eq('user_job_id', userJobId);

      if (error) throw error;
      navigate('/schedule-interview/' + userJobId);
    } catch (error) {
      console.error('Error requesting interview:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!applicant) {
    return <div>Applicant not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>{`${applicant?.users?.name || 'Applicant'} - Details | fetchConsultant`}</title>
        <meta name="description" content={`View details for applicant ${applicant?.users?.name || ''} for the ${applicant?.job?.title || 'job'} position.`} />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="applicant-details" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">{applicant?.users?.name || 'Applicant'}</h1>
            </div>
            <button onClick={() => navigate('/notifications')} className="relative">
              <Bell size={24} />
              {unreadNotifications > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {unreadNotifications}
                </span>
              )}
            </button>
          </header>
          <main className="container mx-auto p-4">
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center mb-4">
              <img src={applicant?.users?.photo || '/default-avatar.png'} alt={applicant?.users?.name || 'Applicant'} className="w-20 h-20 rounded-full mr-4" />
              <div>
                <h2 className="text-2xl font-bold">{applicant?.users?.name || 'Applicant'}</h2>
                <p className="text-gray-600">{applicant?.users?.city || 'City'}, {applicant?.users?.state || 'State'}</p>
              </div>
            </div>

            {/* Video Questions and Responses */}
            {applicant?.job?.video_questions?.length > 0 && (
              <div className="mb-4">
                <h3 className="text-lg font-semibold">Video Responses</h3>
                {applicant.job.video_questions.map((question, index) => (
                  <div key={index} className="mb-4">
                    <p>{question}</p>
                    {applicant.video_responses && applicant.video_responses[index] ? (
                      <button
                        className="text-indigo-600 hover:underline flex items-center"
                        onClick={async () => {
                          try {
                            // Fetch the current video_views
                            let { data: userJob, error: fetchError } = await supabase
                              .from('user_jobs')
                              .select('video_views')
                              .eq('user_job_id', userJobId)
                              .single();

                            if (fetchError) {
                              console.error('Error fetching video views:', fetchError.message);
                              return;
                            }

                            // Parse the video_views or initialize an empty object
                            let videoViews = userJob.video_views || {};

                            // Increment the view count for the specific video
                            videoViews[index] = (videoViews[index] || 0) + 1;

                            // Update the video_views column
                            const { data: updateData, error: updateError } = await supabase
                              .from('user_jobs')
                              .update({ video_views: videoViews })
                              .eq('user_job_id', userJobId);

                            if (updateError) {
                              console.error('Error updating video views:', updateError.message);
                              return;
                            }

                            // Retrieve the public URL for the video
                            const filePath = applicant.video_responses[index];
                            console.log('Corrected file path:', filePath); // Debugging to verify the file path

                            const { data, error: urlError } = await supabase.storage
                            .from('video_responses')
                            .createSignedUrl(filePath, 60); // 60 seconds expiration
                          
                          if (urlError) {
                            console.error("Failed to create signed URL:", urlError.message);
                            return;
                          }
                          
                          console.log('Signed URL:', data.signedUrl); // Debugging to verify the signed URL
                          
                          if (data.signedUrl) {
                            // Open the video in a new tab
                            window.open(data.signedUrl, '_blank');
                          } else {
                            console.error('No signed URL available for this video.');
                          }

                          } catch (error) {
                            console.error("Error handling video response:", error.message);
                          }
                        }}
                      >
                        <PlayCircle size={20} className="mr-2" />
                        Watch Response
                      </button>
                    ) : (
                      <p>No response uploaded for this question</p>
                    )}
                    </div>
                  ))}
                </div>
                    )}

            <div className="mb-4">
                <h3 className="fetch-label">About</h3>
                <ReactQuill
                  value={applicant?.users?.about || 'No information provided'}
                  readOnly={true}
                  theme="bubble"
                />
            </div>
            
            <div className="mb-4">
                <h3 className="fetch-label">Submission Notes</h3>
                <ReactQuill
                  value={applicant?.consultant_notes || 'No notes provided'}
                  readOnly={true}
                  theme="bubble"
                />
            </div>
  
            <div className="mb-4">
              <h3 className="text-lg font-semibold">LinkedIn URL</h3>
              {applicant?.users?.linkedin_url ? (
                <a href={applicant.users.linkedin_url} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">
                  {applicant.users.linkedin_url}
                </a>
              ) : (
                <p>No LinkedIn URL provided</p>
              )}
            </div>

            <div className="mb-4">
              <h3 className="text-lg font-semibold">Resume</h3>
              {applicant?.users?.resume ? (
                <button className="flex items-center text-indigo-600 hover:underline" onClick={() => window.open(applicant.users.resume, '_blank')}>
                  <Download size={20} className="mr-2" />
                  View Resume
                </button>
              ) : (
                <p>No resume available</p>
              )}
            </div>

            <div className="mb-4">
              <h3 className="text-lg font-semibold">Former Epic Employee</h3>
              <p>{applicant?.users?.former_epic ? 'Yes' : 'No'}</p>
            </div>
  
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Epic Certifications</h3>
              <div className="flex flex-wrap">
                {applicant?.users?.user_epic_certifications?.length > 0 ? (
                  applicant.users.user_epic_certifications.map((cert, index) => (
                    <span key={index} className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                      {cert.epic_applications?.name || 'Unknown Certification'}
                    </span>
                  ))
                ) : (
                  <p>No Epic certifications listed</p>
                )}
              </div>
            </div>
  
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Current Project End Date</h3>
              <p>{applicant?.current_project_end_date ? new Date(applicant.current_project_end_date).toLocaleDateString() : 'Not specified'}</p>
            </div>
  
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Available Start Date</h3>
              <p>{applicant?.available_start_date ? new Date(applicant.available_start_date).toLocaleDateString() : 'Not specified'}</p>
            </div>
  
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Consultant Rate</h3>
              <p>{applicant?.consultant_rate ? `$${applicant.consultant_rate}/hr` : 'Not specified'}</p>
            </div>
  
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Average Rating</h3>
              <div className="flex items-center">
                <p className="mr-2">{applicant?.users?.average_rating || 'No ratings yet'}</p>
                {applicant?.users?.average_rating && (
                  <Star className="text-yellow-400" fill="currentColor" size={20} />
                )}
              </div>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold">Reviews</h3>
              {reviews.length > 0 ? (
                reviews.map((review) => (
                  <div key={review.review_id} className="border-b border-gray-200 py-2">
                    <div className="flex justify-between">
                      <span className="font-medium">{review.reviewer.name}</span>
                      <span className="text-sm text-gray-500">{review.review_type}</span>
                    </div>
                    <div className="flex items-center">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          key={i}
                          className={i < review.rating ? "text-yellow-400" : "text-gray-300"}
                          fill={i < review.rating ? "currentColor" : "none"}
                          size={16}
                        />
                      ))}
                    </div>
                    <p className="text-sm mt-1">{review.review_text}</p>
                  </div>
                ))
              ) : (
                <p>No reviews available</p>
              )}
            </div>
  
            {applicant?.fetch_recommended && (
              <div className="mb-4 p-2 bg-green-100 text-green-800 rounded">
                <p className="font-semibold">Fetch Recommended</p>
              </div>
            )}
            <div className="flex justify-between mt-6">
              <button
                onClick={handleReject}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Reject
              </button>
              <button
                onClick={handleInterview}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Interview
              </button>
            </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplicantDetails;