import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserRecord } from '../supabaseAuth';
import { supabase } from '../supabaseClient';


const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error getting session:', error.message);
        navigate('/auth');
        return;
      }

      if (session && session.user) {
        try {
          await createUserRecord(session.user.id, session.user.email);

          const { data, error: userError } = await supabase
            .from('users')
            .select('user_type_id')
            .eq('user_id', session.user.id)
            .single();

          if (userError) throw userError;

          switch (data.user_type_id) {
            case 1:
              navigate('/client-or-consultant');
              break;
            case 2:
              navigate('/consultant-dashboard');
              break;
            case 3:
              navigate('/client-onboarding-personal');
              break;
            case 4:
              navigate('/client-dashboard');
              break;
            case 5:
              navigate('/system-admin-dashboard');
              break;
            default:
              navigate('/client-or-consultant');
          }
        } catch (error) {
          console.error('Error handling auth callback:', error.message);
          navigate('/auth');
        }
      } else {
        navigate('/auth');
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return <div>Finalizing your account setup...</div>;
};

export default AuthCallback;