import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { createZoomMeeting } from '../utils/zoomIntegration';
import ClientHamburger from './ClientHamburger';

const ScheduleInterview = () => {
  const [applicant, setApplicant] = useState(null);
  const [interviewSlots, setInterviewSlots] = useState([]);
  const [notification, setNotification] = useState({ message: '', type: '', show: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const { userJobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Schedule Interview',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchApplicantDetails();
    fetchUnreadNotifications();
  }, [userJobId]);

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };
  
  const fetchApplicantDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          applicant:user_id (name),
          job:job_id (title, job_id)
        `)
        .eq('user_job_id', userJobId)
        .single();
  
      if (error) throw error;
      console.log('Fetched applicant data:', data);
      setApplicant(data);
      console.log('Applicant state:', data);
      setInterviewSlots(data.consultant_slots || []);
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    }
  };

  const handleSlotSelection = async (slot) => {
    setIsLoading(true);
    try {
      console.log('Starting interview scheduling process for slot:', slot);
  
      const meetingDetails = await createZoomMeeting(
        `Interview for ${applicant.job.title} with ${applicant.applicant.name}`,
        new Date(slot).toISOString(),
        60
      );
      console.log('Zoom meeting created successfully:', meetingDetails);
  
      const interviewData = {
        user_job_id: userJobId,
        interview_time: slot,
        zoom_meeting_link: meetingDetails.join_url,
      };
      console.log('Prepared interview data:', interviewData);
  
      const { data: insertedInterview, error: interviewError } = await supabase
        .from('interviews')
        .insert(interviewData)
        .select('id, user_job_id, interview_time, zoom_meeting_link')
        .single();
  
      if (interviewError) {
        console.error('Error inserting interview record:', interviewError);
        throw interviewError;
      }
      console.log('Interview record inserted successfully:', insertedInterview);
  
      const { error: userJobError } = await supabase
        .from('user_jobs')
        .update({ status: 'Interview Scheduled' })
        .eq('user_job_id', userJobId);
  
      if (userJobError) {
        console.error('Error updating user_jobs status:', userJobError);
        throw userJobError;
      }
      console.log('user_jobs status updated successfully');
  
      showNotification("Interview Scheduled! We've notified the applicant and sent Zoom invites.", 'success');
      
      const analytics = getAnalytics();
      logEvent(analytics, 'interview_scheduled', {
        user_job_id: userJobId,
        interview_time: slot
      });
  
      setTimeout(() => {
        console.log('Redirecting to applicants page...');
        navigate(`/applicants/${applicant.job.job_id}`);
      }, 3000);
  
    } catch (error) {
      console.error('Error in handleSlotSelection:', error);
      showNotification(`Error scheduling interview: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type, show: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, show: false });
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return null;
    const date = new Date(dateTimeString);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <>
      <Helmet>
        <title>Schedule Interview | fetchConsultant</title>
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">{applicant?.applicant?.name || 'Schedule Interview'}</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <main className="flex-1 overflow-y-auto p-4">
            <div className="fetch-card">
              <p className="text-gray-600 mb-4">
                Below are the times {applicant?.applicant?.name} said they were available for an interview. 
                Select an option below or propose alternative times if none of the options work.
              </p>

              <h2 className="fetch-label mb-2">Choose a time below to schedule the interview.</h2>
              {interviewSlots.map((slot, index) => (
                <button
                  key={index}
                  onClick={() => handleSlotSelection(slot)}
                  className="fetch-button mb-2 w-full text-left"
                  disabled={isLoading}
                >
                  Slot {index + 1}: {formatDateTime(slot)}
                </button>
              ))}

              {applicant?.consultant_general_availability && (
                <div className="mt-4">
                  <h2 className="fetch-label mb-2">Consultant's General Availability</h2>
                  <ReactQuill
                    value={applicant.consultant_general_availability}
                    readOnly={true}
                    theme="bubble"
                  />
                </div>
              )}

              <button
                onClick={() => navigate(`/propose-interview/${userJobId}`)}
                className="fetch-button mt-4 w-full"
                disabled={isLoading}
              >
                Propose Alternative Times
              </button>

              {isLoading && <p>Scheduling interview...</p>}
            </div>
          </main>

          {notification.show && (
            <div className={`notification notification-${notification.type}`}>
              <div className="flex-1">
                <p className="font-medium">{notification.message}</p>
              </div>
              <button onClick={closeNotification} className="ml-4">
                <X className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleInterview;