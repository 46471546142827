import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationPermissionWarning from './NotificationPermissionWarning';
import Toggle from './Toggle';

const ClientSettings = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notificationPreferences, setNotificationPreferences] = useState({
    user_specific: { push: false, text: false, email: false },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Settings',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchUserPreferences();
    fetchUnreadNotifications();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchUserPreferences = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');
  
      const { data, error } = await supabase
        .from('notification_settings')
        .select('*')
        .eq('user_id', user.id)
        .eq('notification_type', 'user_specific')
        .single();
  
      if (error && error.code !== 'PGRST116') throw error;
  
      setNotificationPreferences({
        user_specific: data?.notification_options || { push: false, text: false, email: false },
      });
    } catch (error) {
      console.error('Error fetching user preferences:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const handleToggleChange = async (option) => {
    let newValue;
    setNotificationPreferences(prev => {
      newValue = !prev.user_specific[option];
      return {
        user_specific: {
          ...prev.user_specific,
          [option]: newValue,
        },
      };
    });

    if (option === 'push' && newValue === true) {
      const permissionGranted = await requestNotificationPermission();
      if (!permissionGranted) {
        setNotificationPreferences(prev => ({
          user_specific: {
            ...prev.user_specific,
            [option]: false,
          },
        }));
        alert("Push notifications cannot be enabled without browser permission.");
      }
    }
  };

  const handleSaveSettings = async () => {
    try {
      setIsLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');
  
      // Delete existing user_specific setting
      await supabase
        .from('notification_settings')
        .delete()
        .eq('user_id', user.id)
        .eq('notification_type', 'user_specific');
  
      // Insert new user_specific setting
      const { error } = await supabase
        .from('notification_settings')
        .insert({
          user_id: user.id,
          notification_type: 'user_specific',
          notification_options: notificationPreferences.user_specific,
        });
  
      if (error) throw error;
  
      alert('Settings saved successfully!');
      navigate('/client-dashboard');
    } catch (error) {
      console.error('Error saving settings:', error.message);
      alert('Failed to save settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Consultant Settings | fetchConsultant</title>
        <meta name="description" content="Manage your notification preferences for Epic certifications and user-specific events on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="settings" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Notification Preferences</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <NotificationPermissionWarning />
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="max-w-2xl mx-auto">
              <section className="fetch-card mb-6">
              <h2 className="text-xl font-semibold mb-4">Notification Preferences</h2>
              <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <span>User Specific Notifications</span>
                    <div className="flex space-x-2">
                      <Toggle
                        enabled={notificationPreferences.user_specific.push}
                        onChange={() => handleToggleChange('push')}
                        label="Push"
                      />
                      <Toggle
                        enabled={notificationPreferences.user_specific.text}
                        onChange={() => handleToggleChange('text')}
                        label="Text"
                      />
                      <Toggle
                        enabled={notificationPreferences.user_specific.email}
                        onChange={() => handleToggleChange('email')}
                        label="Email"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <button onClick={handleSaveSettings} className="fetch-button" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save Settings'}
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientSettings;