import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
import { Filter, Menu } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';
import NotificationPermissionWarning from './NotificationPermissionWarning';
import Spinner from './Spinner'; // Adjust the path if needed

const ConsultantDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);  // Track if it's refreshing
  const [filters, setFilters] = useState({
    epicCertifications: [],
    travel: [],
  });
  const [epicApplications, setEpicApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const observer = useRef();
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchEpicApplications();
    fetchUser();

    const messaging = getMessaging();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      if (payload.notification && payload.data && payload.data.url) {
        toast.info(
          <div>
            <p>{payload.notification.body}</p>
            <button 
              onClick={() => navigate(payload.data.url)}
              className="bg-indigo-600 text-white px-4 py-2 rounded mt-2"
            >
              View Job
            </button>
          </div>,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    fetchJobs();
  }, [page, filters]);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchEpicApplications = async () => {
    try {
      const { data, error } = await supabase
        .from('epic_applications')
        .select('epic_application_id, name')
        .order('name');

      if (error) throw error;
      setEpicApplications(data.map(app => ({ value: app.epic_application_id, label: app.name })));
    } catch (error) {
      console.error('Error fetching Epic applications:', error.message);
    }
  };

  const fetchJobs = async () => {
    try {
      setLoading(true);
  
      // Start building the query
      let query = supabase
        .from('jobs')
        .select(`
          *,
          clients (name),
          jobs_epic_certifications!inner (epic_application_id)
        `)
        .eq('status', 'Active')
        .order('posted_at', { ascending: false })
        .range(page * 10, (page + 1) * 10 - 1);
  
      // Apply epic certifications filter if selected
      if (filters.epicCertifications.length > 0) {
        const epicCertificationIds = filters.epicCertifications.map(c => c.value);
        query = query.in('jobs_epic_certifications.epic_application_id', epicCertificationIds);
      }
  
      // Apply travel filter if selected
      if (filters.travel.length > 0) {
        query = query.in('travel', filters.travel);
      }
  
      // Fetch the data
      const { data, error } = await query;
  
      if (error) throw error;
  
      // Reset jobs when fetching first page or when refreshing
      if (page === 0) {
        setJobs(data);
      } else {
        setJobs(prevJobs => [...prevJobs, ...data]);
      }
  
      setHasMore(data.length === 10); // Check if there are more jobs to load
      setLoading(false);
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
      setLoading(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
    setJobs([]); // Clear jobs when filter changes
    setPage(0); // Reset page to 0 to fetch new jobs based on filters
  };

  // Pull-to-refresh handler
  const handleRefresh = async () => {
    setIsRefreshing(true);
    setJobs([]); // Clear jobs when refreshing
    setPage(0); // Reset pagination
    await fetchJobs();
    setIsRefreshing(false);
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const diffInSeconds = Math.floor((now - postedDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  };

  const trackJobView = async (jobId) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { error } = await supabase
        .from('job_views')
        .insert({
          job_id: jobId,
          user_id: user.id,
          viewed_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error tracking job view:', error.message);
    }
  };

  const handleJobClick = (jobId) => {
    trackJobView(jobId);
    navigate(`/job/${jobId}`);
  };

  const lastJobElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const getJobCardColor = (index) => {
    const colors = ['bg-blue-100', 'bg-green-100', 'bg-yellow-100', 'bg-pink-100', 'bg-purple-100'];
    return colors[index % colors.length];
  };

  return (
    <>
      <Helmet>
        <title>Consultant Dashboard | fetchConsultant</title>
        <meta name="description" content="View and apply to the latest Epic consulting opportunities on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="dashboard" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header
                    className="bg-white p-4 flex justify-between items-center">
                    <div className="flex items-center">
                      <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                        <Menu size={24} />
                      </button>
                      <h1 className="text-xl font-bold">Consultant Dashboard</h1>
                    </div>
                    <div className="flex items-center">
                      <NotificationBell />
                    </div>
                  </header>
                  <NotificationPermissionWarning />
                  <main className="flex-grow container mx-auto px-4 py-8">
                    <PullToRefresh
                      onRefresh={handleRefresh}
                      className="pull-to-refresh"
                      pullingContent={<span>Pull down to refresh...</span>} // Message when pulling down
                      refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>} // Loading spinner while refreshing
                    >
                      <div className="flex flex-col md:flex-row">
                        <div className="md:w-3/4 md:pr-4">
                          <div className="max-w-2xl">
                            {/* Search Field */}
                            <div className="mb-4 relative">
                              <input
                                type="text"
                                placeholder="Search by company, skill, tag..."
                                className="fetch-input pl-10 pr-12 w-full"
                              />
                              {!isDesktop && (
                                <button onClick={() => setIsFilterOpen(!isFilterOpen)} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-indigo-600">
                                  <Filter size={24} />
                                </button>
                              )}
                            </div>
        
                            {/* Recent Posts and Job Count */}
                            <div className="mb-4">
                              <div className="flex justify-between items-center">
                                <h2 className="text-xl font-bold">Recent posts</h2>
                              </div>
                              <p className="text-sm text-gray-600 text-left">{jobs.length} new opportunities posted!</p>
                            </div>
                            {isFilterOpen && !isDesktop && (
                              <div className="mb-4 fetch-filter-container">
                                <h2 className="fetch-filter-title">Filters</h2>
                                <div className="fetch-filter-group">
                                  <label htmlFor="epicCertifications" className="fetch-filter-label">Epic Certifications</label>
                                  <Select
                                    isMulti
                                    name="epicCertifications"
                                    options={epicApplications}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(selectedOptions) => handleFilterChange('epicCertifications', selectedOptions)}
                                    value={filters.epicCertifications}
                                  />
                                </div>
                                <div className="fetch-filter-group">
                                  <label className="fetch-filter-label">Travel</label>
                                  {['100% Remote', 'Hybrid', '100% Onsite'].map((option) => (
                                    <div key={option} className="mb-2">
                                      <input
                                        type="checkbox"
                                        id={option}
                                        name="travel"
                                        value={option}
                                        checked={filters.travel.includes(option)}
                                        onChange={(e) => {
                                          const newTravel = e.target.checked
                                            ? [...filters.travel, option]
                                            : filters.travel.filter(item => item !== option);
                                          handleFilterChange('travel', newTravel);
                                        }}
                                        className="fetch-filter-checkbox"
                                      />
                                      <label htmlFor={option} className="text-sm ml-2">{option}</label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
        
                            {/* Job Cards */}
                            {jobs.map((job, index) => (
                              <div 
                                key={job.job_id} 
                                className="fetch-job-card"
                                onClick={() => handleJobClick(job.job_id)}
                                ref={index === jobs.length - 1 ? lastJobElementRef : null}
                              >
                                <div className="flex flex-col space-y-1">
                                  {/* Job Title and Travel */}
                                  <div className="flex justify-between items-center">
                                    <h2 className="fetch-job-title text-xl font-semibold mb-0">{job.title}</h2>
                                    <span>Ideal Start: {new Date(job.ideal_start_date).toLocaleDateString()}</span>
                                  </div>
        
                                  {/* Client Name, Posted Time Ago, and Duration */}
                                  <div className="flex justify-between items-center text-sm text-gray-600">
                                    <span className="font-semibold">{job.clients.name}</span>
                                    <span>Initial Contract: {job.duration}</span>
                                    <span>{formatTimeAgo(job.posted_at)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {loading && <p className="text-center">Loading...</p>}
                          </div>
                        </div>
        
                        {/* Filters for Desktop */}
                        {isDesktop && (
                          <div className="md:w-1/4">
                            <div className="fetch-filter-container">
                              <h2 className="fetch-filter-title">Filters</h2>
                              <div className="fetch-filter-group">
                                <label htmlFor="epicCertifications" className="fetch-filter-label">Epic Certifications</label>
                                <Select
                                  isMulti
                                  name="epicCertifications"
                                  options={epicApplications}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(selectedOptions) => handleFilterChange('epicCertifications', selectedOptions)}
                                  value={filters.epicCertifications}
                                />
                              </div>
                              <div className="fetch-filter-group">
                                <label className="fetch-filter-label">Travel</label>
                                {['100% Remote', 'Hybrid', '100% Onsite'].map((option) => (
                                  <div key={option} className="mb-2">
                                    <input
                                      type="checkbox"
                                      id={`desktop-${option}`}
                                      name="travel"
                                      value={option}
                                      checked={filters.travel.includes(option)}
                                      onChange={(e) => {
                                        const newTravel = e.target.checked
                                          ? [...filters.travel, option]
                                          : filters.travel.filter(item => item !== option);
                                        handleFilterChange('travel', newTravel);
                                      }}
                                      className="fetch-filter-checkbox"
                                    />
                                    <label htmlFor={`desktop-${option}`} className="text-sm ml-2">{option}</label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </PullToRefresh>
                  </main>
                </div>
              </div>
              <ToastContainer />
            </>
          );
        };
        
        export default ConsultantDashboard;