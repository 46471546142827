import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';

const ConsultantOnboardingProfessional = () => {
  const [formData, setFormData] = useState({
    linkedin_url: '',
    former_epic: false,
    epic_certifications: [],
    current_project_end_date: '',
  });
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [epicApplications, setEpicApplications] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [completionMessage, setCompletionMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Professional' : 'Consultant Onboarding - Professional',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchEpicApplications();
    if (isEditMode) {
      fetchUserData();
    }
  }, [isEditMode]);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select(`
          linkedin_url,
          former_epic,
          current_project_end_date,
          resume,
          user_epic_certifications (epic_application_id)
        `)
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setFormData({
        linkedin_url: data.linkedin_url || '',
        former_epic: data.former_epic || false,
        current_project_end_date: data.current_project_end_date || '',
        epic_certifications: data.user_epic_certifications.map(cert => cert.epic_application_id)
      });
      setResume(data.resume || null);
      setResumeName(data.resume ? data.resume.split('/').pop() : '');
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

    const fetchEpicApplications = async () => {
      try {
        const { data, error } = await supabase
          .from('epic_applications')
          .select('epic_application_id, name');
        
        if (error) {
          console.error('Supabase error fetching Epic applications:', error);
        } else if (data) {
          console.log('Fetched Epic applications:', data);
          setEpicApplications(data);
        } else {
          console.log('No Epic applications data received');
        }
      } catch (err) {
        console.error('Unexpected error fetching Epic applications:', err);
      }
    };

  useEffect(() => {
    const isFormValid = (formData.linkedin_url || '').trim() !== '';
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleResumeUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('Please upload a PDF file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert('File size should not exceed 5MB');
        return;
      }
      setResume(file);
    }
  };

  const handleEpicCertificationChange = (certId) => {
    setFormData(prevData => ({
      ...prevData,
      epic_certifications: prevData.epic_certifications.includes(certId)
        ? prevData.epic_certifications.filter(id => id !== certId)
        : [...prevData.epic_certifications, certId]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValid) return;
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      let resumeUrl = resume;
      if (resume instanceof File) {
        const fileExt = resume.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;

        const { data, error } = await supabase.storage
          .from('resumes')
          .upload(filePath, resume);
        
        if (error) throw error;
        
        const { data: { publicUrl }, error: urlError } = supabase.storage
          .from('resumes')
          .getPublicUrl(filePath);

        if (urlError) throw urlError;
        resumeUrl = publicUrl;
      }

      const { error } = await supabase
        .from('users')
        .update({
          linkedin_url: formData.linkedin_url,
          former_epic: formData.former_epic,
          resume: resumeUrl,
          current_project_end_date: formData.current_project_end_date,
        })
        .eq('user_id', user.id);

      if (error) throw error;

      // Update epic certifications
      await supabase
        .from('user_epic_certifications')
        .delete()
        .eq('user_id', user.id);

      if (formData.epic_certifications.length > 0) {
        await supabase
          .from('user_epic_certifications')
          .insert(formData.epic_certifications.map(certId => ({
            user_id: user.id,
            epic_application_id: certId
          })));
      }

      if (!isEditMode) {
        // Set default notification settings
        await supabase.from('notification_settings').insert([
          {
            user_id: user.id,
            notification_type: 'all_epic_certifications',
            notification_options: { email: true, push: true, text: true }
          },
          {
            user_id: user.id,
            notification_type: 'user_specific',
            notification_options: { email: true, push: true, text: true }
          }
        ]);

        // Request notification permission
        await requestNotificationPermission();
      }

      if (isEditMode) {
        navigate('/consultant-dashboard');
      } else {
        setCompletionMessage({
          title: "Profile Completed!",
          body: "You've been subscribed to all Epic certification notifications via email, push, and text. You can adjust these settings anytime in your profile.",
          showSettings: true
        });
      }
    } catch (error) {
      console.error('Error updating user info:', error.message);
      alert(`Error updating profile: ${error.message}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>{isEditMode ? 'Edit Profile - Professional Information' : 'Consultant Onboarding - Professional Information'} | fetchConsultant</title>
        <meta name="description" content={isEditMode ? "Update your professional profile as a healthcare IT consultant on fetchConsultant." : "Complete your professional profile as a healthcare IT consultant on fetchConsultant. Showcase your Epic certifications and experience to find the best consulting opportunities."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Consultant Onboarding - Personal Information | fetchConsultant" />
        <meta property="og:description" content="Complete your personal profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.fetchconsultant.com/consultant-onboarding-professional" />
        <meta property="og:image" content="https://app.fetchconsultant.com/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="fetch-header-wrapper">
        <div className="fetch-header-content">
          <div className="fetch-header-top">
            <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Consultant Onboarding</h1>
          <div className="fetch-back-button-placeholder"></div>
        </div>
        <div className="fetch-progress">
          <p className="fetch-secondary-text">Step 2 of 2</p>
            <div className="fetch-progress-bar">
            <div className="fetch-progress-bar-fill" style={{width: '100%'}}></div>
            </div>
          </div>
        </div>
      </header>
      <main className="fetch-container">
        <div className="fetch-card">
            <form onSubmit={handleSubmit} className="fetch-form">
            <div>
                <label htmlFor="linkedin_url" className="fetch-label">LinkedIn URL<span className="fetch-required">*</span></label>
                <input
                  type="url"
                  id="linkedin_url"
                  name="linkedin_url"
                  value={formData.linkedin_url}
                  onChange={handleInputChange}
                  required
                  className="fetch-input"
                  placeholder="https://www.linkedin.com/in/johnsmith"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="former_epic"
                  name="former_epic"
                  checked={formData.former_epic}
                  onChange={handleInputChange}
                  className="fetch-checkbox"
                />
                <label htmlFor="former_epic" className="fetch-checkbox-label">
                  Former Epic Employee
                </label>
              </div>
              <div>
                <label htmlFor="resume" className="fetch-label">
                  {resumeName ? 'Update Resume (PDF only)' : 'Upload Resume (PDF only)'}
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="file"
                    id="resume"
                    accept=".pdf"
                    onChange={handleResumeUpload}
                    className="hidden"
                  />
                  <label
                    htmlFor="resume"
                    className="file-input-button"
                  >
                    <Upload className="w-5 h-5 mr-2" />
                    {resumeName ? 'Update Resume' : 'Upload Resume'}
                  </label>
                  {(resume instanceof File ? resume.name : resumeName) && (
                    <span className="ml-3 text-sm text-gray-600">
                      {resume instanceof File ? resume.name : resumeName}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label className="fetch-label mb-2">Epic Certifications</label>
                <div className="fetch-card">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {epicApplications.map((app) => (
                      <div key={app.epic_application_id} className="flex items-center">
                        <input
                          type="checkbox"
                          id={`cert-${app.epic_application_id}`}
                          name="epic_certifications"
                          value={app.epic_application_id}
                          checked={formData.epic_certifications.includes(app.epic_application_id)}
                          onChange={() => handleEpicCertificationChange(app.epic_application_id)}
                          className="fetch-checkbox"
                        />
                        <label htmlFor={`cert-${app.epic_application_id}`} className="fetch-checkbox-label truncate">
                          {app.name}
                        </label>
                      </div> 
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="current_project_end_date" className="fetch-label">Current Project End Date</label>
                <input
                  type="date"
                  id="current_project_end_date"
                  name="current_project_end_date"
                  value={formData.current_project_end_date}
                  onChange={handleInputChange}
                  className="fetch-input"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!formValid}
                >
                  Finish
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
      {completionMessage && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{completionMessage.title}</h3>
            <div className="mt-2 px-7 py-3">
              <p className="text-sm text-gray-500">{completionMessage.body}</p>
              {completionMessage.warning && (
                <p className="text-sm text-yellow-600 mt-2">{completionMessage.warning}</p>
              )}
            </div>
            <div className="items-center px-4 py-3">
              {completionMessage.showSettings && (
                <button
                  onClick={() => navigate('/consultant-settings')}
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Go to Settings
                </button>
              )}
              <button
                onClick={() => navigate('/consultant-dashboard')}
                className="mt-3 px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsultantOnboardingProfessional;