import { Bell } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const NotificationBanner = () => {
  const [pendingReviewsCount, setPendingReviewsCount] = useState(0);

  useEffect(() => {
    fetchPendingReviews();
  }, []);

  const fetchPendingReviews = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('login_email, work_email')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      const { count, error } = await supabase
        .from('review_tokens')
        .select('id', { count: 'exact' })
        .is('used_at', null)
        .or(`reviewer_email.eq.${userData.login_email},reviewer_email.eq.${userData.work_email}`);

      if (error) throw error;
      setPendingReviewsCount(count);
    } catch (error) {
      console.error('Error fetching pending reviews count:', error);
    }
  };

  if (pendingReviewsCount === 0) return null;

  return (
    <div className="bg-indigo-600 text-white py-2 px-4">
      <div className="container mx-auto flex justify-between items-center">
        <p className="flex items-center">
          <Bell className="h-5 w-5 mr-2" />
          You have {pendingReviewsCount} pending review{pendingReviewsCount !== 1 ? 's' : ''}
        </p>
        <Link to="/notifications" className="underline">
          View all
        </Link>
      </div>
    </div>
  );
};

export default NotificationBanner;