import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientApproveHours = () => {
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Approve Hours',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchTimesheetEntries();
  }, []);

  const fetchTimesheetEntries = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('timesheet_entries')
        .select(`
          *,
          users:consultant_id (name),
          jobs (title)
        `)
        .eq('jobs.created_by', user.id)
        .eq('status', 'submitted')
        .order('date', { ascending: false });

      if (error) throw error;
      setTimesheetEntries(data);
    } catch (error) {
      console.error('Error fetching timesheet entries:', error.message);
    }
  };

  const handleApprove = async (entryId) => {
    try {
      const { error } = await supabase
        .from('timesheet_entries')
        .update({ status: 'approved' })
        .eq('id', entryId);

      if (error) throw error;
      alert('Hours approved successfully');
      fetchTimesheetEntries();
    } catch (error) {
      console.error('Error approving hours:', error.message);
      alert('Error approving hours. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Approve Consultant Hours | fetchConsultant</title>
        <meta name="description" content="Review and approve consultant hours for your projects on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Approve Hours</h1>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            <h2 className="text-xl font-bold mb-4">Pending Timesheet Entries</h2>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Consultant</th>
                  <th className="text-left">Job</th>
                  <th className="text-left">Date</th>
                  <th className="text-left">Hours</th>
                  <th className="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {timesheetEntries.map((entry) => (
                  <tr key={entry.id}>
                    <td>{entry.users.name}</td>
                    <td>{entry.jobs.title}</td>
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                    <td>{entry.hours}</td>
                    <td>
                      <button
                        onClick={() => handleApprove(entry.id)}
                        className="fetch-button"
                      >
                        Approve
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default ClientApproveHours;