import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const SystemAdminHoursDashboard = () => {
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Hours Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchTimesheetEntries();
  }, [filter]);

  const fetchTimesheetEntries = async () => {
    try {
      let query = supabase
        .from('timesheet_entries')
        .select(`
          *,
          users:consultant_id (name),
          jobs (title),
          clients:jobs(name)
        `)
        .order('date', { ascending: false });

      if (filter !== 'all') {
        query = query.eq('status', filter);
      }

      const { data, error } = await query;

      if (error) throw error;
      setTimesheetEntries(data);
    } catch (error) {
      console.error('Error fetching timesheet entries:', error.message);
    }
  };

  const handleStatusChange = async (entryId, newStatus) => {
    try {
      const { error } = await supabase
        .from('timesheet_entries')
        .update({ status: newStatus })
        .eq('id', entryId);

      if (error) throw error;
      alert(`Status updated to ${newStatus} successfully`);
      fetchTimesheetEntries();
    } catch (error) {
      console.error('Error updating status:', error.message);
      alert('Error updating status. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>System Admin Hours Dashboard | fetchConsultant</title>
        <meta name="description" content="Manage and oversee consultant hours across all projects on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Hours Dashboard</h1>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            <div className="mb-4">
              <label htmlFor="filter" className="fetch-label">Filter by Status</label>
              <select
                id="filter"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="fetch-select"
              >
                <option value="all">All</option>
                <option value="submitted">Submitted</option>
                <option value="approved">Approved</option>
                <option value="invoiced">Invoiced</option>
                <option value="paid">Paid</option>
              </select>
            </div>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Consultant</th>
                  <th className="text-left">Client</th>
                  <th className="text-left">Job</th>
                  <th className="text-left">Date</th>
                  <th className="text-left">Hours</th>
                  <th className="text-left">Status</th>
                  <th className="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {timesheetEntries.map((entry) => (
                  <tr key={entry.id}>
                    <td>{entry.users.name}</td>
                    <td>{entry.clients.name}</td>
                    <td>{entry.jobs.title}</td>
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                    <td>{entry.hours}</td>
                    <td>{entry.status}</td>
                    <td>
                      {entry.status === 'approved' && (
                        <button
                          onClick={() => handleStatusChange(entry.id, 'invoiced')}
                          className="fetch-button"
                        >
                          Mark as Invoiced
                        </button>
                      )}
                      {entry.status === 'invoiced' && (
                        <button
                          onClick={() => handleStatusChange(entry.id, 'paid')}
                          className="fetch-button"
                        >
                          Mark as Paid
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default SystemAdminHoursDashboard;