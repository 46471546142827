import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setIsAuthenticated(true);
        const { data, error } = await supabase
          .from('users')
          .select('user_type_id')
          .eq('user_id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching user type:', error);
        } else {
          setUserType(data.user_type_id);
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  // Allow access to ConsultantJobDescription without authentication
  if (location.pathname.startsWith('/job/')) {
    return <Outlet />;
  }

  // Allow access to ReviewBenefits page without authentication
  if (location.pathname.startsWith('/review-benefits/')) {
    return <Outlet />;
  }  

  if (!isAuthenticated) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  let redirectPath = '/';
  switch (userType) {
    case 1:
      redirectPath = '/client-or-consultant';
      break;
    case 2:
      redirectPath = '/consultant-dashboard';
      break;
    case 3:
      redirectPath = '/client-onboarding-personal';
      break;
    case 4:
      redirectPath = '/client-dashboard';
      break;
    case 5:
      redirectPath = '/system-admin-dashboard';
      break;
    default:
      redirectPath = '/client-or-consultant';
  }

  if (location.pathname === '/') {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;