import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientOrConsultant = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'User Type Selection',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const checkSession = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();
        if (error) throw error;
        if (!data.session) {
          console.log("No active session found");
          navigate('/auth');
          return;
        }
        
        const { user } = data.session;
        if (!user.email_confirmed_at) {
          console.log("Email not verified");
          navigate('/verify-email');
          return;
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error checking session:', error.message);
        navigate('/auth');
      }
    };

    checkSession();
  }, [navigate]);

  const handleUserTypeSelection = async (userType) => {
    try {
      const { data, error } = await supabase.auth.getUser();
      
      if (error || !data.user) {
        console.error("No user found");
        navigate('/auth');
        return;
      }

      const { error: updateError } = await supabase
        .from('users')
        .update({ user_type_id: userType === 'client' ? 3 : 2 }) // Assuming 3 is for Unverified Client and 2 is for Consultant
        .eq('user_id', data.user.id);

      if (updateError) throw updateError;

      navigate(userType === 'client' ? '/client-onboarding-personal' : '/consultant-onboarding-personal');
    } catch (error) {
      console.error('Error updating user type:', error.message);
      // Handle error (show error message to user)
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
        <title>Choose Your Role | fetchConsultant</title>
        <meta name="description" content="Are you a healthcare IT consultant or a healthcare organization looking for Epic talent? Choose your role to get started with fetchConsultant." />
      </Helmet>
  
      {/* Split screen */}
      <div className="flex w-full max-w-4xl shadow-lg">
  
        {/* Left section */}
        <div className="bg-white p-8 w-full sm:max-w-md flex flex-col justify-center items-center">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              How Can We Help?
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Are you a Consultant or a Client?
            </p>
          </div>
  
          <div className="mt-8 w-full sm:w-auto flex flex-col space-y-6 items-center">
            <button
              onClick={() => handleUserTypeSelection('client')}
              className="w-full sm:w-72 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Post jobs as a Client
            </button>
  
            <button
              onClick={() => handleUserTypeSelection('consultant')}
              className="w-full sm:w-72 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Find jobs as a Consultant
            </button>
          </div>
        </div>
  
        {/* Right section */}
        <div className="hidden md:flex md:flex-col md:justify-center bg-indigo-600 text-white p-10 w-1/2">
          <h3 className="text-3xl font-extrabold text-center">Why fetchConsultant</h3>
          <ul className="space-y-6 font-semibold mt-8">
            <li className="flex items-start">
              <svg className="h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-lg">Find top healthcare IT consultants easily.</span>
            </li>
            <li className="flex items-start">
              <svg className="h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
              </svg>
              <span className="text-lg">Effortlessly connect with clients and consultants.</span>
            </li>
            <li className="flex items-start">
              <svg className="h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
              </svg>
              <span className="text-lg">Effortlessly connect with clients and consultants.</span>
            </li>
            <li className="flex items-start">
              <svg className="h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
              </svg>
              <span className="text-lg">Effortlessly connect with clients and consultants.</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClientOrConsultant;