import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu, Plus, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';

const ApplyJobInterview = () => {
  const [job, setJob] = useState(null);
  const [interviewSlots, setInterviewSlots] = useState(['']);
  const [generalAvailability, setGeneralAvailability] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Apply for Job - Interview',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobDetails();
    fetchUser();
    fetchUnreadNotifications();
  }, [jobId]);

  useEffect(() => {
    const isValid = interviewSlots.filter(slot => slot !== '').length > 0 || generalAvailability.trim() !== '';
    setFormValid(isValid);
  }, [interviewSlots, generalAvailability]);

  const fetchJobDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('title')
        .eq('job_id', jobId)
        .single();

      if (error) throw error;
      setJob(data);
    } catch (error) {
      console.error('Error fetching job details:', error.message);
    }
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const handleAddSlot = () => {
    if (interviewSlots.length < 5) {
      setInterviewSlots([...interviewSlots, '']);
    }
  };

  const handleRemoveSlot = (index) => {
    const newSlots = interviewSlots.filter((_, i) => i !== index);
    setInterviewSlots(newSlots);
  };

  const handleSlotChange = (index, value) => {
    const newSlots = [...interviewSlots];
    const localTime = new Date(value);
    const utcTime = new Date(localTime.getTime() - localTime.getTimezoneOffset() * 60000);
    newSlots[index] = utcTime.toISOString();
    setInterviewSlots(newSlots);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValid) return;
  
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const validSlots = interviewSlots.filter(slot => slot !== '').map(slot => new Date(slot).toISOString());
  
      let { data: userJob, error: userJobError } = await supabase
        .from('user_jobs')
        .select('user_job_id')
        .eq('user_id', user.id)
        .eq('job_id', jobId)
        .single();
  
      if (userJobError) {
        if (userJobError.code === 'PGRST116') {
          const { data: newUserJob, error: newUserJobError } = await supabase
            .from('user_jobs')
            .insert({
              user_id: user.id,
              job_id: jobId,
              status: 'Applied',
              consultant_slots: validSlots,
              consultant_general_availability: generalAvailability,
              submitted_at: new Date().toISOString()
            })
            .select()
            .single();
  
          if (newUserJobError) throw newUserJobError;
          userJob = newUserJob;
        } else {
          throw userJobError;
        }
      } else {
        const { error: updateError } = await supabase
          .from('user_jobs')
          .update({
            consultant_slots: validSlots,
            consultant_general_availability: generalAvailability,
            submitted_at: new Date().toISOString(),
            status: 'Applied'
          })
          .eq('user_job_id', userJob.user_job_id);
  
        if (updateError) throw updateError;
      }
  
      const analytics = getAnalytics();
      logEvent(analytics, 'submit_application', {
        job_id: jobId,
      });

      alert('Applied! Your information has been shared with the client.');
      navigate('/consultant-jobs-applied');
    } catch (error) {
      console.error('Error submitting application:', error);
      alert(`Error submitting application: ${error.message || 'Unknown error'}. Please try again.`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Apply for Job - Interview | fetchConsultant</title>
        <meta name="description" content="Schedule your interview for an Epic consulting position on fetchConsultant." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="apply" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Apply for Job - Interview</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
              <form onSubmit={handleSubmit} className="fetch-form">
              <div>
                <label className="fetch-label mb-4">Please choose a few times you are available to interview for this position. All slots are for one hour.<span className="fetch-required">*</span></label>
                {interviewSlots.map((slot, index) => (
                  <div key={index} className="mb-4 flex items-center">
                    <div className="flex-grow mr-2">
                      <label htmlFor={`slot-${index}`} className="fetch-label">Slot {index + 1}</label>
                      <input
                        type="datetime-local"
                        id={`slot-${index}`}
                        value={slot ? new Date(slot).toISOString().slice(0, 16) : ''}
                        onChange={(e) => handleSlotChange(index, e.target.value)}
                        className="fetch-input w-full"
                      />
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveSlot(index)}
                        className="text-red-600 self-end mb-2"
                        aria-label="Remove slot"
                      >
                        <X size={20} />
                      </button>
                    )}
                  </div>
                ))}
                {interviewSlots.length < 5 && (
                  <button type="button" onClick={handleAddSlot} className="fetch-button bg-gray-200 text-gray-700 mt-4">
                    <Plus className="inline-block mr-2" size={20} />
                    Add Slot
                  </button>
                )}
              </div>
              <div className="mb-4">
                <label htmlFor="consultant_general_availability" className="fetch-label">
                    Describe your general availability if none of the above times work for the client
                </label>
                <ReactQuill
                  theme="snow"
                  value={generalAvailability}
                  onChange={setGeneralAvailability}
                  className="fetch-input"
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{'list': 'ordered'}, {'list': 'bullet'}],
                      ['link'],
                    ],
                  }}
                />
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!formValid}
                >
                  Submit Application
                </button>
              </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplyJobInterview;