import { supabase } from "./supabaseClient.js"

export const signInWithGoogle = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
  })
  if (error) throw error
  return data
}

export const signInWithApple = async () => {
  console.log("Initiating Apple sign in...");
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'apple',
    options: {
      redirectTo: window.location.origin,
    }
  });
  if (error) {
    console.error("Supabase Apple sign in error:", error);
    throw error;
  }
  console.log("Supabase Apple sign in data:", data);
  return data;
}

export const signUpWithEmail = async (email, password) => {
  console.log("Attempting to sign up with email:", email);
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: `${window.location.origin}/auth/callback`
    }
  });
  if (error) {
    console.error("Sign up error:", error);
    throw error;
  }
  console.log("Sign up successful:", data);
  return { user: data.user, session: data.session };
}

export const signInWithEmail = async (email, password) => {
  console.log("Attempting to sign in with email:", email);
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  if (error) {
    console.error("Sign in error:", error);
    throw error;
  }
  console.log("Sign in successful:", data);
  return { user: data.user, session: data.session };
}

export const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) throw error
}

export const getCurrentUser = async () => {
  const { data, error } = await supabase.auth.getUser()
  if (error) throw error
  return data.user
}

export const onAuthStateChange = (callback) => {
  return supabase.auth.onAuthStateChange((event, session) => {
    callback(session?.user || null)
  })
}

export const checkEmailExists = async (email) => {
  try {
    const { data, error } = await supabase
      .rpc('check_email_exists', { email_to_check: email });

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error checking email existence:', error);
    throw error;
  }
};

export const resetPasswordForEmail = async (email) => {
  const { data, error } = await supabase.auth.resetPasswordForEmail(email);
  if (error) throw error;
  return data;
};

export const createUserRecord = async (userId, email, userTypeId = 1) => {
  console.log("Checking/Creating user record for:", userId, email);
  try {
    // First, check if the user already exists
    let { data: existingUser, error: fetchError } = await supabase
      .from('users')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') {
      console.error("Error checking for existing user:", fetchError);
      throw fetchError;
    }

    if (existingUser) {
      console.log("User already exists:", existingUser);
      return existingUser;
    }

    // If user doesn't exist, create a new record
    const { data, error } = await supabase
      .from('users')
      .insert([
        {
          user_id: userId,
          login_email: email,
          user_type_id: userTypeId,
          created_at: new Date()
        }
      ])
      .single();

    if (error) {
      console.error("Error creating user record:", error);
      throw error;
    }
    console.log("User record created successfully:", data);
    return data;
  } catch (error) {
    console.error("Unexpected error checking/creating user record:", error);
    throw error;
  }
}