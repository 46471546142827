import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientOnboardingPersonal = () => {
  const [formData, setFormData] = useState({
    name: '',
    work_email: '',
    phone: '',
  });
  const [originalWorkEmail, setOriginalWorkEmail] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Personal' : 'Client Onboarding - Personal',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserData();
  }, [isEditMode]);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('name, work_email, phone')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setFormData({
        name: data.name || '',
        work_email: data.work_email || '',
        phone: formatPhoneNumber(data.phone ? data.phone.toString() : '') || '',
      });
      setOriginalWorkEmail(data.work_email || '');
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  useEffect(() => {
    const isFormValid = (formData.name || '').trim() !== '' &&
                        (formData.work_email || '').trim() !== '' &&
                        String(formData.phone || '').trim() !== '';
  
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const formattedPhone = formatPhoneNumber(value);
      setFormData(prevData => ({
        ...prevData,
        [name]: formattedPhone
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const unformatPhoneNumber = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const phoneNumber = unformatPhoneNumber(formData.phone);
      const phoneNumberAsNumber = phoneNumber ? parseInt(phoneNumber, 10) : null;

      let updateData = {
        name: formData.name,
        work_email: formData.work_email,
        phone: phoneNumberAsNumber,
        last_updated_at: new Date().toISOString(),
      };

      // Check if work email has changed
      if (formData.work_email !== originalWorkEmail) {
        // Generate a new verification token
        const token = Math.random().toString(36).substr(2, 15);
        
        updateData = {
          ...updateData,
          user_type_id: 3, // Set back to Unverified Client
          work_email_verification_token: token,
          work_email_verification_sent_at: new Date().toISOString(),
          work_email_verified_at: null
        };

        // Send verification email
        const { error: emailError } = await supabase.functions.invoke('send-verification-email', {
          body: { email: formData.work_email, token: token }
        });

        if (emailError) {
          console.error('Error sending verification email:', emailError);
          throw emailError;
        }
      }

      const { error } = await supabase
        .from('users')
        .update(updateData)
        .eq('user_id', user.id);

      if (error) throw error;

      if (isEditMode) {
        if (formData.work_email !== originalWorkEmail) {
          setShowVerificationMessage(true);
        } else {
          navigate('/client-edit-profile-professional', { state: { isEditMode: true } });
        }
      } else {
        navigate('/client-onboarding-professional');
      }
    } catch (error) {
      console.error('Error updating user info:', error.message);
      // Handle error (show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  if (showVerificationMessage) {
    return (
      <div className="min-h-screen bg-white flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold mb-4">Verification Email Sent</h2>
        <p className="text-center mb-4">
          Please check your email to verify your updated client account. 
          Once verified, you'll be able to access the Client Dashboard.
        </p>
        <button
          onClick={() => navigate('/auth')}
          className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
        >
          Return to Login
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
        <title>{isEditMode ? 'Edit Profile - Personal Information' : 'Client Onboarding - Personal Information'} | fetchConsultant</title>
        <meta name="description" content={isEditMode ? "Update your personal profile as a healthcare organization on fetchConsultant." : "Complete your personal profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Client Onboarding - Personal Information | fetchConsultant" />
        <meta property="og:description" content="Complete your personal profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.fetchconsultant.com/client-onboarding-personal" />
        <meta property="og:image" content="https://app.fetchconsultant.com/path-to-image.jpg" />
      </Helmet>
      
      {/* Split screen */}
      <div className="flex w-full max-w-4xl shadow-lg">
  
        {/* Left section */}
        <div className="bg-white p-8 w-full sm:max-w-md flex flex-col justify-center items-center">
          <header className="fetch-header w-full">
            <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
            <h1 className="fetch-page-title">{isEditMode ? 'Edit Profile' : 'Client Onboarding'}</h1>
          </header>
  
          <main className="fetch-container">
            <div className="fetch-card">
              {!isEditMode && (
                <div className="mb-6 text-center">
                  <p className="fetch-secondary-text">Step 1 of 2</p>
                  <div className="fetch-progress-bar">
                    <div className="fetch-progress-bar-fill" style={{ width: '50%' }}></div>
                  </div>
                </div>
              )}
              {/* Form area (unchanged) */}
              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="name" className="fetch-label">Name<span className="fetch-required">*</span></label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                    placeholder="Full Name"
                  />
                </div>
                <div>
                  <label htmlFor="work_email" className="fetch-label">Employer Email<span className="fetch-required">*</span></label>
                  <input
                    type="email"
                    id="work_email"
                    name="work_email"
                    value={formData.work_email}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="fetch-label">Phone Number<span className="fetch-required">*</span></label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="(###) ###-####"
                    maxLength="14"
                    required
                    className="fetch-input"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className={`fetch-button ${!formValid || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!formValid || isLoading}
                  >
                    {isLoading ? 'Processing...' : 'Next'}
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
  
        {/* Right section */}
        <div className="hidden md:block bg-indigo-600 text-white p-10 w-1/2">
          <h2 className="mt-4 text-center text-3xl font-extrabold">Why fetchConsultant</h2>
          <ul className="space-y-6 mt-8 flex flex-col justify-center">
            <li>
            <div className="flex items-start mt-2">
              <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
              </svg>
              <h3 className="text-lg text-left font-semibold">Transparent, Fair Pricing</h3>
              </div>
              <p className="text-left ml-10">One rate with a simple 9% platform fee. No hidden costs, no inflated rates. Fair for everyone.</p>
            </li>
            <li>
            <div className="flex items-start mt-2">
                <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              <h3 className="text-lg text-left font-semibold">Real-Time Opportunities</h3>
              </div>
                <p className="text-left ml-10">Instant notifications for new jobs and applicants. Fill positions in days, not weeks.</p>
            </li>
            <li>
            <div className="flex items-start mt-2">
                <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                </svg>
              <h3 className="text-lg text-left font-semibold">Streamlined Contracting Process</h3>
              </div>
                <p className="text-left ml-10">From job posting to Zoom interviews, our integrated platform accelerates every step of hiring.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClientOnboardingPersonal;