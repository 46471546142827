import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, ChevronLeft, Copy, Menu, Star, Video } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';

const Applicants = () => {
  const [applicants, setApplicants] = useState({});
  const [noApplicants, setNoApplicants] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [job, setJob] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { jobId } = useParams();

  useEffect(() => {
    console.log('Current jobId:', jobId);
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Applicants',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchApplicants();
    fetchJobDetails();
    fetchUnreadNotifications();
    fetchUser();
  }, [jobId]);

  const fetchApplicants = async () => {
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          users!user_jobs_user_id_fkey (
            user_id,
            name,
            average_rating,
            former_epic
          ),
          job:job_id (
            created_by
          ),
          referrer:referred_by (
            user_id,
            name
          ),
          interviews (
            interview_time,
            zoom_meeting_link
          )
        `)
        .eq('job_id', jobId)
        .order('submitted_at', { ascending: false });
  
      if (!data || data.length === 0) {
        setApplicants({});
        setNoApplicants(true);
      } else {
        const groupedApplicants = data.reduce((acc, applicant) => {
          if (!acc[applicant.status]) {
            acc[applicant.status] = [];
          }
          acc[applicant.status].push(applicant);
          return acc;
        }, {});
        setApplicants(groupedApplicants);
        setNoApplicants(false);
      }
    } catch (error) {
      console.error('Error fetching applicants:', error.message);
      setNoApplicants(true);
    }
  };

  const fetchJobDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('title, status')
        .eq('job_id', jobId)
        .single();
  
      if (error) throw error;
      setJob(data);
      setShareLink(`${window.location.origin}/job/${jobId}`);
    } catch (error) {
      console.error('Error fetching job details:', error.message);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      alert('Share link copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleApplicantClick = (userJobId) => {
    navigate(`/applicant/${userJobId}`);
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Star
          key={i}
          size={18}
          fill={i <= rating ? '#8d63f3' : 'none'}
          stroke={i <= rating ? '#8d63f3' : '#8c8d98'}
        />
      );
    }
    return stars;
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return null;
    const date = new Date(dateTimeString);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const renderApplicantsByStatus = () => {
    return Object.entries(applicants).map(([status, statusApplicants]) => (
      <div key={status} className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{status}</h2>
        <div className="space-y-4">
          {statusApplicants.map((applicant) => (
            <div
              key={applicant.user_job_id}
              className="fetch-job-card cursor-pointer"
              onClick={() => handleApplicantClick(applicant.user_job_id)}
            >
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h3 className="fetch-job-title">{applicant.users?.name || 'Unknown'}</h3>
                  <p className="fetch-job-details">Consultant Rate: ${applicant.consultant_rate}/hr</p>
                  <p className="fetch-job-details">Available Start Date: {new Date(applicant.available_start_date).toLocaleDateString()}</p>
                  {applicant.interviews && applicant.interviews[0] && (
                    <p className="fetch-job-details">Interview Time: {formatDateTime(applicant.interviews[0].interview_time)}</p>
                  )}
                </div>
                <div className="flex flex-col items-end">
                  {applicant.fetch_recommended && (
                    <span className="fetch-tag fetch-tag-recommended">
                      Fetch Recommended
                    </span>
                  )}
                  {applicant.users?.former_epic && (
                    <span className="fetch-tag fetch-tag-former-epic">
                      Former Epic Employee
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center mt-2">
                {renderStars(applicant.users?.average_rating || 0)}
              </div>
              {(status === 'Interview Scheduled' || status === 'Second Interview Scheduled') && applicant.interviews && applicant.interviews[0] && applicant.interviews[0].zoom_meeting_link && (
                <a
                  href={applicant.interviews[0].zoom_meeting_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fetch-button-secondary mt-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Video size={18} className="mr-2" />
                  Join Interview
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Applicants | fetchConsultant</title>
        <meta name="description" content="View applicants for your job posting on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="applicants" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate('/client-dashboard')} />
              <h1 className="text-xl font-bold ml-4">Applicants for {job?.title}</h1>
            </div>
            <button onClick={() => navigate('/notifications')} className="relative">
              <Bell size={24} />
              {unreadNotifications > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {unreadNotifications}
                </span>
              )}
            </button>
          </header>
          <main className="fetch-container pb-20">
            {noApplicants ? (
              <div className="text-center py-8">
                <p className="text-gray-600 mb-4">No applicants for this job yet.</p>
                <p className="text-gray-600 mb-4">Share this job to get more applicants:</p>
                <div className="flex justify-center items-center space-x-2">
                  <input
                    type="text"
                    value={shareLink}
                    readOnly
                    className="fetch-input w-64"
                  />
                  <button
                    onClick={copyShareLink}
                    className="bg-indigo-600 text-white p-2 rounded-full hover:bg-indigo-700 transition duration-300"
                  >
                    <Copy size={20} />
                  </button>
                </div>
              </div>
            ) : (
              renderApplicantsByStatus()
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default Applicants;