import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { initializeFCM } from './firebase/fcm';
import { onMessageListener, requestForToken, setupTokenRefresh } from './firebaseConfig';
import './styles/tailwind.css';

// Component imports
import AddJob from './components/AddJob';
import ApplicantDetails from './components/ApplicantDetails';
import Applicants from './components/Applicants';
import ApplyJob from './components/ApplyJob';
import ApplyJobInterview from './components/ApplyJobInterview';
import AuthCallback from './components/AuthCallback';
import AuthScreen from './components/AuthScreen';
import ClientApproveHours from './components/ClientApproveHours';
import ClientDashboard from './components/ClientDashboard';
import ClientOnboardingPersonal from './components/ClientOnboardingPersonal';
import ClientOnboardingProfessional from './components/ClientOnboardingProfessional';
import ClientOrConsultant from './components/ClientOrConsultant';
import ClientSettings from './components/ClientSettings';
import ConsultantDashboard from './components/ConsultantDashboard';
import ConsultantJobDescription from './components/ConsultantJobDescription';
import ConsultantJobsApplied from './components/ConsultantJobsApplied';
import ConsultantOnboardingPersonal from './components/ConsultantOnboardingPersonal';
import ConsultantOnboardingProfessional from './components/ConsultantOnboardingProfessional';
import ConsultantRequests from './components/ConsultantRequests';
import ConsultantSettings from './components/ConsultantSettings';
import ConsultantTimesheet from './components/ConsultantTimesheet';
import EngagementManagement from './components/EngagementManagement';
import ForgotPassword from './components/ForgotPassword';
import InterviewFeedback from './components/InterviewFeedback';
import InterviewFeedbackView from './components/InterviewFeedbackView';
import Interviews from './components/Interviews';
import NotFoundPage from './components/NotFoundPage';
import NotificationBanner from './components/NotificationBanner';
import Notifications from './components/Notifications';
import Notifications2 from './components/Notifications2';
import ProposeInterview from './components/ProposeInterview';
import ProtectedRoute from './components/ProtectedRoute';
import RequestSecondInterview from './components/RequestSecondInterview';
import ResetPassword from './components/ResetPassword';
import ReviewBenefits from './components/ReviewBenefits';
import Reviews from './components/Reviews';
import RoleBasedRoute from './components/RoleBasedRoute';
import ScheduleInterview from './components/ScheduleInterview';
import SolicitReview from './components/SolicitReview';
import SubmitReview from "./components/SubmitReview";
import SystemAdminDashboard from './components/SystemAdminDashboard';
import SystemAdminHoursDashboard from './components/SystemAdminHoursDashboard';
import TermsAndConditions from './components/TermsAndConditions';
import VerifyEmail from './components/VerifyEmail';
import VerifyWorkEmail from './components/VerifyWorkEmail';

function App() {
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'app_opened');

    initializeFCM();
    requestForToken();
    setupTokenRefresh();

    const unsubscribe = onMessageListener().then((payload) => {
      console.log('Received foreground message ', payload);
      // You can handle the received notification here
    }).catch((err) => console.log('failed: ', err));

    return () => {
      unsubscribe.catch((err) => console.log('failed: ', err));
    };
  }, []);
  
  return (
    <Router>
      <div className="App">
        <NotificationBanner />
        <Routes>
          {/* Public routes */}
          <Route path="/auth" element={<AuthScreen />} />
          <Route path="/" element={<Navigate to="/auth" replace />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-work-email" element={<VerifyWorkEmail />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/review-benefits/:token" element={<ReviewBenefits />} />
          <Route path="/job/:jobId" element={<ConsultantJobDescription />} />


          {/* Protected Common routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/client-or-consultant" element={<ClientOrConsultant />} />
            <Route path="/submit-review/:token" element={<SubmitReview />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/notifications2" element={<Notifications2 />} />
            <Route path="/interviews" element={<RoleBasedRoute component={Interviews} allowedTypes={[2, 4]} fallbackPath="/auth" />} />
            <Route path="/interview-feedback/:userJobId" element={<RoleBasedRoute component={InterviewFeedback} allowedTypes={[2, 4]} fallbackPath="/auth" />} />
            <Route path="/view-feedback/:userJobId" element={<RoleBasedRoute component={InterviewFeedbackView} allowedTypes={[2, 4]} fallbackPath="/auth" />} />
            <Route path="/engagement-management" element={<RoleBasedRoute component={EngagementManagement} allowedTypes={[2, 4]} fallbackPath="/auth" />} />
            
            {/* Consultant routes */}
            <Route path="/consultant-onboarding-personal" element={<RoleBasedRoute component={ConsultantOnboardingPersonal} allowedTypes={[2]} fallbackPath="/auth" />} />
            <Route path="/consultant-onboarding-professional" element={<RoleBasedRoute component={ConsultantOnboardingProfessional} allowedTypes={[2]} fallbackPath="/auth" />} />
            <Route path="/consultant-dashboard" element={<RoleBasedRoute component={ConsultantDashboard} allowedTypes={[2]} fallbackPath="/auth" />} />
            <Route path="/solicit-review" element={<SolicitReview />} />
            <Route path="/consultant-edit-profile" element={<RoleBasedRoute component={ConsultantOnboardingPersonal} allowedTypes={[2]} fallbackPath="/auth" />} />
            <Route path="/consultant-edit-profile-professional" element={<RoleBasedRoute component={ConsultantOnboardingProfessional} allowedTypes={[2]} fallbackPath="/auth" />} />
            <Route path="/consultant-jobs-applied" element={<ConsultantJobsApplied />} />
            <Route path="/consultant-settings" element={<RoleBasedRoute component={ConsultantSettings} allowedTypes={[2]} fallbackPath="/auth" />} />
            <Route path="/apply-job/:jobId" element={<ApplyJob />} />
            <Route path="/terms-and-conditions/:jobId" element={<TermsAndConditions />} />
            <Route path="/apply-job-interview/:jobId" element={<ApplyJobInterview />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/consultant-timesheet" element={<RoleBasedRoute component={ConsultantTimesheet} allowedTypes={[2]} fallbackPath="/auth" />} />
            <Route path="/consultant-requests" element={<RoleBasedRoute component={ConsultantRequests} allowedTypes={[2]} fallbackPath="/auth" />} />

            {/* Client routes */}
            <Route path="/client-onboarding-personal" element={<RoleBasedRoute component={ClientOnboardingPersonal} allowedTypes={[3, 4]} fallbackPath="/auth" />} />
            <Route path="/client-onboarding-professional" element={<RoleBasedRoute component={ClientOnboardingProfessional} allowedTypes={[3, 4]} fallbackPath="/auth" />} />
            <Route path="/client-dashboard" element={<RoleBasedRoute component={ClientDashboard} allowedTypes={[4]} fallbackPath="/auth" />} />
            <Route path="/client-edit-profile" element={<RoleBasedRoute component={ClientOnboardingPersonal} allowedTypes={[3, 4]} fallbackPath="/auth" />} />
            <Route path="/client-edit-profile-professional" element={<RoleBasedRoute component={ClientOnboardingProfessional} allowedTypes={[3, 4]} fallbackPath="/auth" />} />
            <Route path="/applicants/:jobId" element={<Applicants />} />
            <Route path="/applicant/:userJobId" element={<ApplicantDetails />} />
            <Route path="/schedule-interview/:userJobId" element={<ScheduleInterview />} />
            <Route path="/propose-interview/:userJobId" element={<ProposeInterview />} />
            <Route path="/request-second-interview/:userJobId" element={<RoleBasedRoute component={RequestSecondInterview} allowedTypes={[4]} fallbackPath="/auth" />} />
            <Route path="/client-settings" element={<RoleBasedRoute component={ClientSettings} allowedTypes={[4]} fallbackPath="/auth" />} />
            <Route path="/add-job" element={<RoleBasedRoute component={AddJob} allowedTypes={[4]} fallbackPath="/auth" />} />
            <Route path="/edit-job/:jobId" element={<RoleBasedRoute component={AddJob} allowedTypes={[4]} fallbackPath="/auth" />} />
            <Route path="/client-approve-hours" element={<RoleBasedRoute component={ClientApproveHours} allowedTypes={[4]} fallbackPath="/auth" />} />

            {/* Admin route */}
            <Route path="/system-admin-dashboard" element={<RoleBasedRoute component={SystemAdminDashboard} allowedTypes={[5]} fallbackPath="/auth" />} />
            <Route path="/system-admin-hours-dashboard" element={<RoleBasedRoute component={SystemAdminHoursDashboard} allowedTypes={[5]} fallbackPath="/auth" />} />
          </Route>

          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;