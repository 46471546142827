import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
import { Bell, Menu, Video } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationPermissionWarning from './NotificationPermissionWarning';
import Spinner from './Spinner';

const ConsultantJobsApplied = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const observer = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Applied Jobs',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchUnreadNotifications();

    const messaging = getMessaging();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      if (payload.notification && payload.data && payload.data.url) {
        toast.info(
          <div>
            <p>{payload.notification.body}</p>
            <button 
              onClick={() => navigate(payload.data.url)}
              className="bg-indigo-600 text-white px-4 py-2 rounded mt-2"
            >
              View Details
            </button>
          </div>,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    fetchAppliedJobs();
  }, [page]);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const fetchAppliedJobs = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      let query = supabase
        .from('user_jobs')
        .select(`
          *,
          job:jobs (
            *,
            client:clients (name)
          ),
          interviews (
            id,
            interview_time,
            zoom_meeting_link,
            feedback,
            rating
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .range(page * 10, (page + 1) * 10 - 1);

      const { data, error } = await query;

      if (error) throw error;

      if (page === 0) {
        setAppliedJobs(data);
      } else {
        setAppliedJobs(prevJobs => [...prevJobs, ...data]);
      }

      setHasMore(data.length === 10);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching applied jobs:', error.message);
      setLoading(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setAppliedJobs([]);
    setPage(0);
    await fetchAppliedJobs();
    setIsRefreshing(false);
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const diffInSeconds = Math.floor((now - postedDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  };

  const lastJobElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  return (
    <>
      <Helmet>
        <title>Applied Jobs | fetchConsultant</title>
        <meta name="description" content="View and manage your applied Epic consulting opportunities on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="applied" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Applied Jobs</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <NotificationPermissionWarning />
          <main className="flex-grow container mx-auto px-4 py-8">
            <PullToRefresh
              onRefresh={handleRefresh}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              <div className="max-w-2xl mx-auto">
                {appliedJobs.map((job, index) => (
                  <div 
                    key={job.user_job_id} 
                    className="fetch-job-card"
                    ref={index === appliedJobs.length - 1 ? lastJobElementRef : null}
                  >
                    <div className="flex flex-col space-y-2">
                      <h2 className="fetch-job-title text-xl font-semibold">{job.job.title}</h2>
                      <p className="fetch-job-company">{job.job.client.name}</p>
                      <p className="fetch-job-details">Status: {job.status}</p>
                      <p className="fetch-job-details">Applied: {formatTimeAgo(job.created_at)}</p>
                      {job.interviews && job.interviews.length > 0 && (
                        <div>
                          <p className="fetch-job-details">
                            Interview: {new Date(job.interviews[0].interview_time).toLocaleString()}
                          </p>
                          {job.interviews[0].zoom_meeting_link && (
                             <a
                              href={job.interviews[0].zoom_meeting_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="fetch-button-secondary mt-2 inline-flex items-center"
                            >
                              <Video size={18} className="mr-2" />
                              Join Interview
                            </a>
                          )}
                        </div>
                      )}
                      {job.interviews && job.interviews[0] && job.interviews[0].feedback && (
                        <div>
                          <p className="fetch-job-details">Feedback: {job.interviews[0].feedback}</p>
                          {job.interviews[0].rating && (
                            <p className="fetch-job-details">Rating: {job.interviews[0].rating}/5</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {loading && <p className="text-center">Loading...</p>}
              </div>
            </PullToRefresh>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConsultantJobsApplied;