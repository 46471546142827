import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Star } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const InterviewFeedbackView = () => {
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { interviewId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Interview Feedback View',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchFeedbackAndDetails();
  }, [interviewId]);

  const fetchFeedbackAndDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('interviews')
        .select(`
          *,
          user_job:user_jobs (
            job:jobs(title, client:clients(name)),
            consultant:users!user_jobs_user_id_fkey(name)
          ),
          reviewer:users!reviewer_id(name)
        `)
        .eq('id', interviewId)
        .single();

      if (error) throw error;
      setInterviewDetails(data);
    } catch (error) {
      console.error('Error fetching feedback and details:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        size={20}
        fill={index < rating ? 'gold' : 'none'}
        stroke={index < rating ? 'gold' : 'currentColor'}
      />
    ));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Interview Feedback | fetchConsultant</title>
        <meta name="description" content="View feedback for your interview on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100">
        <header className="bg-white shadow-md p-4 flex items-center">
          <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
          <h1 className="text-xl font-bold ml-4">Interview Feedback</h1>
        </header>
        <main className="container mx-auto p-4">
          {interviewDetails && (
            <div className="bg-white p-6 rounded-lg shadow-md mb-6">
              <h2 className="text-2xl font-bold mb-2">{interviewDetails.user_job.job.title}</h2>
              <p className="mb-1">Client: {interviewDetails.user_job.job.client.name}</p>
              <p className="mb-1">Consultant: {interviewDetails.user_job.consultant.name}</p>
              <p className="mb-4">Interview Date: {new Date(interviewDetails.interview_time).toLocaleString()}</p>
            </div>
          )}
          {interviewDetails && interviewDetails.feedback ? (
            <div className="bg-white p-6 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Feedback from {interviewDetails.reviewer.name}</h3>
                <div className="flex">{renderStars(interviewDetails.rating)}</div>
              </div>
              <p className="text-gray-700">{interviewDetails.feedback}</p>
              <p className="text-sm text-gray-500 mt-2">Submitted on: {new Date(interviewDetails.feedback_submitted_at).toLocaleString()}</p>
            </div>
          ) : (
            <p>No feedback has been submitted for this interview yet.</p>
          )}
        </main>
      </div>
    </>
  );
};

export default InterviewFeedbackView;