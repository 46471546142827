import { getAnalytics, logEvent } from "firebase/analytics";
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const SystemAdminDashboard = () => {
  React.useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>System Admin Dashboard | fetchConsultant</title>
        <meta name="description" content="System administration dashboard for fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header className="bg-white shadow-md p-4 flex justify-between items-center">
          <h1 className="text-xl font-bold">System Admin Dashboard</h1>
        </header>
        <main className="flex-grow container mx-auto px-4 py-8">
          {/* Add system admin dashboard content here */}
          <p>Welcome to the System Admin Dashboard</p>
          <Link to="/system-admin-hours-dashboard" className="fetch-button mt-4">
          Manage Consultant Hours
        </Link>
        </main>
      </div>
    </>
  );
};

export default SystemAdminDashboard;