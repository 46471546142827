import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu, Plus, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationPermissionWarning from './NotificationPermissionWarning';
import Toggle from './Toggle';

const ConsultantSettings = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [epicCertifications, setEpicCertifications] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [notificationPreferences, setNotificationPreferences] = useState({
    allEpicCertifications: { push: false, text: false, email: false },
    user_specific: { push: false, text: false, email: false },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Settings',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchEpicCertifications();
    fetchUserPreferences();
    fetchUnreadNotifications();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const fetchEpicCertifications = async () => {
    try {
      const { data, error } = await supabase
        .from('epic_applications')
        .select('epic_application_id, name')
        .order('name');
      if (error) throw error;
      setEpicCertifications(data);
    } catch (error) {
      console.error('Error fetching Epic certifications:', error.message);
    }
  };

  const fetchUserPreferences = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');
  
      const { data, error } = await supabase
        .from('notification_settings')
        .select('*')
        .eq('user_id', user.id);
  
      if (error) throw error;
  
      if (data && data.length > 0) {
        const transformedPreferences = {
          allEpicCertifications: data.find(pref => pref.notification_type === 'all_epic_certifications')?.notification_options || { push: false, text: false, email: false },
          user_specific: data.find(pref => pref.notification_type === 'user_specific')?.notification_options || { push: false, text: false, email: false },
        };
        setNotificationPreferences(transformedPreferences);
  
        const selectedCerts = data
          .filter(pref => pref.notification_type === 'epic_certification')
          .map(pref => ({
            epic_application_id: pref.epic_application_id,
            notification_options: pref.notification_options,
          }));
        setSelectedCertifications(selectedCerts);
      }
    } catch (error) {
      console.error('Error fetching user preferences:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleChange = async (category, option) => {
    let newValue;
    setNotificationPreferences(prev => {
      newValue = !prev[category][option];
      return {
        ...prev,
        [category]: {
          ...prev[category],
          [option]: newValue,
        },
      };
    });

    if (option === 'push' && newValue === true) {
      const permissionGranted = await requestNotificationPermission();
      if (!permissionGranted) {
        setNotificationPreferences(prev => ({
          ...prev,
          [category]: {
            ...prev[category],
            [option]: false,
          },
        }));
        alert("Push notifications cannot be enabled without browser permission.");
      }
    }
  };

  const handleCertificationToggle = (certId, option) => {
    setSelectedCertifications(prev => {
      const index = prev.findIndex(cert => cert.epic_application_id === certId);
      if (index !== -1) {
        const updatedCerts = [...prev];
        updatedCerts[index] = {
          ...updatedCerts[index],
          notification_options: {
            ...updatedCerts[index].notification_options,
            [option]: !updatedCerts[index].notification_options[option],
          },
        };
        return updatedCerts;
      } else {
        return [
          ...prev,
          {
            epic_application_id: certId,
            notification_options: { push: false, text: false, email: false, [option]: true },
          },
        ];
      }
    });
  };

  const handleAddCertification = () => {
    if (selectedCertifications.some(cert => !cert.epic_application_id)) {
      alert('Please select a certification for the empty row before adding a new one.');
      return;
    }
    setSelectedCertifications(prev => [
      ...prev,
      { epic_application_id: null, notification_options: { push: false, text: false, email: false } },
    ]);
  };

  const handleRemoveCertification = (index) => {
    setSelectedCertifications(prev => prev.filter((_, i) => i !== index));
  };

  const handleCertificationChange = (index, certId) => {
    setSelectedCertifications(prev => {
      const updatedCerts = [...prev];
      updatedCerts[index] = { ...updatedCerts[index], epic_application_id: certId };
      return updatedCerts;
    });
  };

  const handleSaveSettings = async () => {
    try {
      setIsLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');
  
      // Validation for certifications
      const invalidCertifications = selectedCertifications.filter(cert => 
        (!cert.epic_application_id && (cert.notification_options.push || cert.notification_options.text || cert.notification_options.email)) ||
        (cert.epic_application_id && !cert.notification_options.push && !cert.notification_options.text && !cert.notification_options.email)
      );
  
      if (invalidCertifications.length > 0) {
        alert('Please ensure all selected certifications have an application chosen and at least one notification method selected.');
        return;
      }
  
      // Delete existing settings
      await supabase
        .from('notification_settings')
        .delete()
        .eq('user_id', user.id)
        .in('notification_type', ['all_epic_certifications', 'user_specific']);
  
      await supabase
        .from('notification_settings')
        .delete()
        .eq('user_id', user.id)
        .eq('notification_type', 'epic_certification');
  
      // Prepare valid settings
      const validSettings = [
        // All Epic Certifications
        {
          user_id: user.id,
          notification_type: 'all_epic_certifications',
          notification_options: notificationPreferences.allEpicCertifications,
          epic_application_id: null,
        },
        // User Specific
        {
          user_id: user.id,
          notification_type: 'user_specific',
          notification_options: notificationPreferences.user_specific,
          epic_application_id: null,
        },
        // Specific Epic Certifications
        ...selectedCertifications
          .filter(cert => cert.epic_application_id)
          .map(cert => ({
            user_id: user.id,
            notification_type: 'epic_certification',
            notification_options: cert.notification_options,
            epic_application_id: cert.epic_application_id,
          })),
      ];
  
      // Insert new settings
      const { error } = await supabase
        .from('notification_settings')
        .insert(validSettings);
  
      if (error) throw error;
  
      alert('Settings saved successfully!');
      navigate('/consultant-dashboard');
    } catch (error) {
      console.error('Error saving settings:', error.message);
      alert('Failed to save settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Consultant Settings | fetchConsultant</title>
        <meta name="description" content="Manage your notification preferences for Epic certifications and user-specific events on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="settings" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Notification Preferences</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <NotificationPermissionWarning />
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="max-w-2xl mx-auto">
              <section className="fetch-card mb-6">
                <h2 className="text-lg font-medium mb-2">Epic Certifications</h2>
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <span>All Epic Certifications</span>
                    <div className="flex space-x-2">
                      <Toggle
                        enabled={notificationPreferences.allEpicCertifications.push}
                        onChange={() => handleToggleChange('allEpicCertifications', 'push')}
                        label="Push"
                      />
                      <Toggle
                        enabled={notificationPreferences.allEpicCertifications.text}
                        onChange={() => handleToggleChange('allEpicCertifications', 'text')}
                        label="Text"
                      />
                      <Toggle
                        enabled={notificationPreferences.allEpicCertifications.email}
                        onChange={() => handleToggleChange('allEpicCertifications', 'email')}
                        label="Email"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="fetch-card mb-6">
                <h3 className="text-lg font-medium mb-2">Specific Epic Certifications</h3>
                <div className="space-y-4">
                  {selectedCertifications.map((cert, index) => (
                    <div key={index} className="flex items-center justify-between">
                      <select
                        value={cert.epic_application_id || ''}
                        onChange={(e) => handleCertificationChange(index, e.target.value)}
                        className="fetch-select"
                      >
                        <option value="">Select Certification</option>
                        {epicCertifications.map((epicCert) => (
                          <option key={epicCert.epic_application_id} value={epicCert.epic_application_id}>
                            {epicCert.name}
                          </option>
                        ))}
                      </select>
                      <div className="flex space-x-2">
                        <Toggle
                          enabled={cert.notification_options.push}
                          onChange={() => handleCertificationToggle(cert.epic_application_id, 'push')}
                          label="Push"
                        />
                        <Toggle
                          enabled={cert.notification_options.text}
                          onChange={() => handleCertificationToggle(cert.epic_application_id, 'text')}
                          label="Text"
                        />
                        <Toggle
                          enabled={cert.notification_options.email}
                          onChange={() => handleCertificationToggle(cert.epic_application_id, 'email')}
                          label="Email"
                        />
                      </div>
                      <button onClick={() => handleRemoveCertification(index)} className="text-red-600">
                        <X size={20} />
                      </button>
                    </div>
                  ))}
                  <button onClick={handleAddCertification} className="fetch-button flex items-center justify-center">
                    <Plus size={20} className="mr-2" /> Add Certification
                  </button>
                </div>
              </section>
              <section className="fetch-card mb-6">
                <h3 className="text-lg font-medium mb-2">User Specific Notifications</h3>
                <div className="flex items-center justify-between">
                  <span>User Specific</span>
                  <div className="flex space-x-2">
                    <Toggle
                      enabled={notificationPreferences.user_specific.push}
                      onChange={() => handleToggleChange('user_specific', 'push')}
                      label="Push"
                    />
                    <Toggle
                      enabled={notificationPreferences.user_specific.text}
                      onChange={() => handleToggleChange('user_specific', 'text')}
                      label="Text"
                    />
                    <Toggle
                      enabled={notificationPreferences.user_specific.email}
                      onChange={() => handleToggleChange('user_specific', 'email')}
                      label="Email"
                    />
                  </div>
                </div>
              </section>
              <button onClick={handleSaveSettings} className="fetch-button">
                Save Settings
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ConsultantSettings;