import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu, Star } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [averageRatings, setAverageRatings] = useState({ overall: 0, peer: 0, manager: 0 });
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Reviews',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserAndReviews();
  }, []);

  const fetchUserAndReviews = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;
      setUser(userData);

      const { data: reviewsData, error: reviewsError } = await supabase
        .from('reviews')
        .select(`
          *,
          reviewer:users!reviews_reviewer_id_fkey(
            name,
            employer
          )
        `)
        .eq('consultant_id', user.id)
        .order('created_at', { ascending: false });

      if (reviewsError) throw reviewsError;

      // Fetch client information for each review
      const reviewsWithClients = await Promise.all(
        reviewsData.map(async (review) => {
          if (review.reviewer.employer) {
            const { data: clientData, error: clientError } = await supabase
              .from('clients')
              .select('name')
              .eq('client_id', review.reviewer.employer)
              .single();

            if (clientError) {
              console.error('Error fetching client:', clientError);
              return { ...review, clientName: null };
            }

            return { ...review, clientName: clientData.name };
          }
          return { ...review, clientName: null };
        })
      );

      setReviews(reviewsWithClients);

      // Calculate average ratings
      const peerReviews = reviewsWithClients.filter(review => review.review_type === 'peer');
      const managerReviews = reviewsWithClients.filter(review => review.review_type === 'manager');

      const calcAverage = (reviews) => reviews.length > 0 ? reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length : 0;

      setAverageRatings({
        overall: calcAverage(reviewsWithClients),
        peer: calcAverage(peerReviews),
        manager: calcAverage(managerReviews)
      });

    } catch (error) {
      console.error('Error fetching user and reviews:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        size={20}
        fill={index < Math.round(rating) ? 'gold' : 'none'}
        stroke={index < Math.round(rating) ? 'gold' : 'currentColor'}
      />
    ));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>My Reviews | fetchConsultant</title>
        <meta name="description" content="View your reviews and ratings on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="reviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center shadow-md">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">My Reviews</h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => navigate('/solicit-review')}
                className="mr-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
              >
                Solicit Review
              </button>
              <button onClick={() => navigate('/notifications')} className="relative">
                <Bell size={24} />
              </button>
            </div>
          </header>
          <main className="bg-white flex-grow container mx-auto px-4 py-8">
            
            {/* Average Ratings Card */}
            <div className="fetch-job-card text-center rounded-lg shadow-md p-6 mb-6">
              <h2 className="text-2xl font-bold mb-4">Average Ratings</h2>
              {/* Overall Rating */}
              <div className="flex justify-between items-center mb-2">
                <span className="text-left">Overall Rating:</span>
                <div className="flex items-center">
                  <span className="mr-2">({reviews.length})</span>
                  {renderStars(averageRatings.overall)}
                  <span className="ml-2 font-bold">{averageRatings.overall.toFixed(1)}</span>
                </div>
              </div>
              {/* Manager Rating */}
              <div className="flex justify-between items-center mb-2">
                <span className="text-left">Manager Rating:</span>
                <div className="flex items-center">
                  <span className="mr-2">({reviews.filter(review => review.review_type === 'manager').length})</span>
                  {renderStars(averageRatings.manager)}
                  <span className="ml-2 font-bold">{averageRatings.manager.toFixed(1)}</span>
                </div>
              </div>
              {/* Peer Rating */}
              <div className="flex justify-between items-center">
                <span className="text-left">Peer Rating:</span>
                <div className="flex items-center">
                  <span className="mr-2">({reviews.filter(review => review.review_type === 'peer').length})</span>
                  {renderStars(averageRatings.peer)}
                  <span className="ml-2 font-bold">{averageRatings.peer.toFixed(1)}</span>
                </div>
              </div>
            </div>
    
            {/* Reviews Section */}
            <div className="space-y-6">
              {reviews.map((review) => (
                <div key={review.review_id} className="fetch-job-card">
                  <div className="flex flex-col space-y-1">

                    {/* First row: Review Type (left) and Star Rating (right) */}
                    <div className="flex justify-between items-center">
                      <span className="bg-indigo-100 text-indigo-700 font-semibold px-2 py-1 rounded-md">
                        {review.review_type.charAt(0).toUpperCase() + review.review_type.slice(1)} Review
                      </span>
                      <div className="flex items-center">
                        {renderStars(review.rating)}
                        <span className="ml-2 font-bold">{review.rating.toFixed(1)}</span>
                      </div>
                    </div>

                    {/* Second row: Reviewer Name (left) and Date (right) */}
                    <div className="flex justify-between items-center mt-2">
                      <h3 className="text-xl font-semibold">{review.reviewer.name}</h3>
                      <span className="text-gray-500">{new Date(review.created_at).toLocaleDateString()}</span>
                    </div>

                    {/* Third row: Client Name (if applicable) */}
                    {review.clientName && (
                      <div className="mt-2">
                        <span className="text-gray-600 text-left">{review.clientName}</span>
                      </div>
                    )}

                    {/* Fourth row: Review Text */}
                    <p className="text-gray-700 mt-2 text-left">{review.review_text}</p>
                  </div>
                </div>
              ))}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Reviews;