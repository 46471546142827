import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Helmet } from 'react-helmet';
import { getAnalytics, logEvent } from "firebase/analytics";

const VerifyWorkEmail = () => {
  const [status, setStatus] = useState('verifying');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Verify Work Email',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const verifyEmail = async () => {
      const token = new URLSearchParams(location.search).get('token');
      if (!token) {
        setStatus('error');
        return;
      }

      try {
        const { data, error } = await supabase
          .from('users')
          .update({ 
            work_email_verified_at: new Date().toISOString(),
            work_email_verification_token: null,
            user_type_id: 4 // Assuming 4 is the id for verified client
          })
          .eq('work_email_verification_token', token)
          .select();

        if (error) throw error;
        if (data) {
          setStatus('success');
          setTimeout(() => navigate('/client-dashboard'), 3000);
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setStatus('error');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <>
      <Helmet>
        <title>Verify Work Email | fetchConsultant</title>
        <meta name="description" content="Verify your work email to complete your client registration on fetchConsultant." />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="min-h-screen bg-white flex flex-col items-center justify-center">
        {status === 'verifying' && (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Verifying your email...</h2>
            <p>Please wait while we confirm your email address.</p>
          </div>
        )}
        {status === 'success' && (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Email Verified Successfully!</h2>
            <p>Your client account has been verified. You will be redirected to the Client Dashboard in a few seconds.</p>
          </div>
        )}
        {status === 'error' && (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Verification Error</h2>
            <p>There was an error verifying your email. Please try again or contact support.</p>
            <button
              onClick={() => navigate('/login')}
              className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
            >
              Return to Login
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default VerifyWorkEmail;