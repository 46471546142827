import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ConsultantTimesheet = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [date, setDate] = useState('');
  const [hours, setHours] = useState('');
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Timesheet',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobs();
    fetchTimesheetEntries();
  }, []);

  const fetchJobs = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('user_jobs')
        .select('job_id, jobs(title)')
        .eq('user_id', user.id)
        .eq('status', 'Contract Accepted');

      if (error) throw error;
      setJobs(data);
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
    }
  };

  const fetchTimesheetEntries = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('timesheet_entries')
        .select('*')
        .eq('consultant_id', user.id)
        .order('date', { ascending: false });

      if (error) throw error;
      setTimesheetEntries(data);
    } catch (error) {
      console.error('Error fetching timesheet entries:', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('timesheet_entries')
        .insert({
          consultant_id: user.id,
          job_id: selectedJob,
          date,
          hours: parseFloat(hours),
          status: 'submitted'
        });

      if (error) throw error;
      alert('Hours submitted successfully');
      setSelectedJob('');
      setDate('');
      setHours('');
      fetchTimesheetEntries();
    } catch (error) {
      console.error('Error submitting hours:', error.message);
      alert('Error submitting hours. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Consultant Timesheet | fetchConsultant</title>
        <meta name="description" content="Submit and view your timesheet entries as a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Timesheet</h1>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            <form onSubmit={handleSubmit} className="fetch-form">
              <div>
                <label htmlFor="job" className="fetch-label">Select Job</label>
                <select
                  id="job"
                  value={selectedJob}
                  onChange={(e) => setSelectedJob(e.target.value)}
                  required
                  className="fetch-select"
                >
                  <option value="">Select a job</option>
                  {jobs.map((job) => (
                    <option key={job.job_id} value={job.job_id}>
                      {job.jobs.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="date" className="fetch-label">Date</label>
                <input
                  type="date"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  className="fetch-input"
                />
              </div>
              <div>
                <label htmlFor="hours" className="fetch-label">Hours</label>
                <input
                  type="number"
                  id="hours"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                  step="0.5"
                  min="0"
                  max="24"
                  required
                  className="fetch-input"
                />
              </div>
              <button type="submit" className="fetch-button">Submit Hours</button>
            </form>
          </div>
          <div className="fetch-card mt-6">
            <h2 className="text-xl font-bold mb-4">Recent Timesheet Entries</h2>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Date</th>
                  <th className="text-left">Hours</th>
                  <th className="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {timesheetEntries.map((entry) => (
                  <tr key={entry.id}>
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                    <td>{entry.hours}</td>
                    <td>{entry.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default ConsultantTimesheet;