import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Star } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const InterviewFeedback = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [interviewDetails, setInterviewDetails] = useState(null);
  const { interviewId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Interview Feedback',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchInterviewDetails();
  }, [interviewId]);

  const fetchInterviewDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('interviews')
        .select(`
          *,
          user_job:user_jobs (
            job:jobs(title),
            consultant:users!user_jobs_user_id_fkey(name)
          )
        `)
        .eq('id', interviewId)
        .single();

      if (error) throw error;
      setInterviewDetails(data);
    } catch (error) {
      console.error('Error fetching interview details:', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { error } = await supabase
        .from('interviews')
        .update({
          rating,
          feedback,
          reviewer_id: user.id,
          feedback_submitted_at: new Date().toISOString()
        })
        .eq('id', interviewId);

      if (error) throw error;

      alert('Feedback submitted successfully!');
      navigate('/interviews');
    } catch (error) {
      console.error('Error submitting feedback:', error.message);
      alert('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Interview Feedback | fetchConsultant</title>
        <meta name="description" content="Provide feedback for your recent interview on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100">
        <header className="bg-white shadow-md p-4 flex items-center">
          <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
          <h1 className="text-xl font-bold ml-4">Interview Feedback</h1>
        </header>
        <main className="container mx-auto p-4">
          {interviewDetails && (
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4">{interviewDetails.user_job.job.title}</h2>
              <p className="mb-4">Consultant: {interviewDetails.user_job.consultant.name}</p>
              <p className="mb-6">Interview Date: {new Date(interviewDetails.interview_time).toLocaleString()}</p>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block mb-2 font-semibold">Rating</label>
                  <div className="flex space-x-2">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        size={32}
                        onClick={() => setRating(star)}
                        fill={star <= rating ? 'gold' : 'none'}
                        stroke={star <= rating ? 'gold' : 'currentColor'}
                        className="cursor-pointer"
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="feedback" className="block mb-2 font-semibold">Feedback</label>
                  <textarea
                    id="feedback"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    className="w-full p-2 border rounded"
                    rows="4"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="bg-indigo-600 text-white px-6 py-2 rounded hover:bg-indigo-700">
                  Submit Feedback
                </button>
              </form>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default InterviewFeedback;