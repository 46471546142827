import React from 'react';
import { Helmet } from 'react-helmet';

const VerifyEmail = () => {
  return (
    <>
      <Helmet>
        <title>Verify Your Email - FetchConsultant</title>
        <meta name="description" content="Please verify your email address to continue with FetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verify Your Email
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            We've sent a verification link to your email address. Please check your inbox and click the link to verify your account.
          </p>
          <p className="mt-2 text-center text-sm text-gray-600">
            After verifying, you'll be automatically redirected to complete your account setup.
          </p>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;