import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';

const ClientOnboardingProfessional = () => {
  const [formData, setFormData] = useState({
    employer: '',
    job_title: '',
    linkedin_url: '',
  });
  const [clients, setClients] = useState([]);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [formValid, setFormValid] = useState(false); // Track form validation
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Professional' : 'Client Onboarding - Professional',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserAndClients();
  }, [isEditMode]);

  const fetchUserAndClients = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const [userData, clientsData] = await Promise.all([
        supabase
          .from('users')
          .select('employer, job_title, linkedin_url, login_email, work_email')
          .eq('user_id', user.id)
          .single(),
        supabase
          .from('clients')
          .select('client_id, name, email_domain1, email_domain2')
          .order('name', { ascending: true })
      ]);
  
      if (userData.error) throw userData.error;
      if (clientsData.error) throw clientsData.error;
  
      setClients(clientsData.data);
  
      const loginEmailDomain = userData.data.login_email.split('@')[1];
      const workEmailDomain = userData.data.work_email ? userData.data.work_email.split('@')[1] : null;
  
      const matchingClient = clientsData.data.find(client =>
        client.email_domain1 === loginEmailDomain || 
        client.email_domain2 === loginEmailDomain ||
        (workEmailDomain && (client.email_domain1 === workEmailDomain || client.email_domain2 === workEmailDomain))
      );
  
      setFormData({
        employer: matchingClient ? matchingClient.client_id : (userData.data.employer || ''),
        job_title: userData.data.job_title || '',
        linkedin_url: userData.data.linkedin_url || '',
      });
  
    } catch (error) {
      console.error('Error fetching user and clients:', error.message);
    }
  };

  useEffect(() => {
        // Ensure that all formData fields are strings before trimming
        const isFormValid = (formData.employer || '').trim() !== '' &&
                        (formData.job_title || '').trim() !== '';
  
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { error } = await supabase
        .from('users')
        .update({
          employer: formData.employer,
          job_title: formData.job_title,
          linkedin_url: formData.linkedin_url,
        })
        .eq('user_id', user.id);

      if (error) throw error;

      if (!isEditMode) {
        // Set default notification settings
        await supabase.from('notification_settings').insert({
          user_id: user.id,
          notification_type: 'user_specific',
          notification_options: { email: true, push: true, text: true }
        });

        // Request notification permission
        await requestNotificationPermission();
      }

      if (isEditMode) {
        navigate('/client-dashboard');
      } else {
        setShowVerificationMessage(true);
      }
    } catch (error) {
      console.error('Error updating user info:', error.message);
      // Handle error (show error message to user)
    }
  };

  if (showVerificationMessage) {
    return (
      <div className="min-h-screen bg-white flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold mb-4">Verification Email Sent</h2>
        <p className="text-center mb-4">
          Please check your email to verify your client account. 
          Once verified, you'll be able to access the Client Dashboard.
        </p>
        <button
          onClick={() => navigate('/auth')}
          className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
        >
          Return to Login
        </button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{isEditMode ? 'Edit Profile - Professional Information' : 'Client Onboarding - Professional Information'} | fetchConsultant</title>
        <meta name="description" content={isEditMode ? "Update your professional profile as a healthcare organization on fetchConsultant." : "Complete your professional profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Client Onboarding - Professional Information | fetchConsultant" />
        <meta property="og:description" content="Complete your professional profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.fetchconsultant.com/client-onboarding-professional" />
        <meta property="og:image" content="https://app.fetchconsultant.com/path-to-image.jpg" />
        </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">{isEditMode ? 'Edit Profile' : 'Client Onboarding'}</h1>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            {!isEditMode && (
              <div className="mb-6 text-center">
                <p className="fetch-secondary-text">Step 2 of 2</p>
                <div className="fetch-progress-bar">
                  <div className="fetch-progress-bar-fill" style={{width: '100%'}}></div>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit} className="fetch-form">
              <div>
                <label htmlFor="employer" className="fetch-label">Employer<span className="fetch-required">*</span></label>
                <div className="relative">
                  <select
                    id="employer"
                    name="employer"
                    value={formData.employer}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                  >
                    <option value="">Select Employer</option>
                    {clients.map(client => (
                      <option key={client.client_id} value={client.client_id}>{client.name}</option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="job_title" className="fetch-label">Job Title<span className="fetch-required">*</span></label>
                <input
                  type="text"
                  id="job_title"
                  name="job_title"
                  value={formData.job_title}
                  onChange={handleInputChange}
                  required
                  className="fetch-input"
                  placeholder="Director of Epic Applications"
                />
              </div>
              <div>
                <label htmlFor="linkedin_url" className="fetch-label">LinkedIn URL</label>
                <input
                  type="url"
                  id="linkedin_url"
                  name="linkedin_url"
                  value={formData.linkedin_url}
                  onChange={handleInputChange}
                  className="fetch-input"
                  placeholder="https://www.linkedin.com/in/yourprofile"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!formValid}
                >
                  {isEditMode ? 'Save Changes' : 'Finish'}
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default ClientOnboardingProfessional;