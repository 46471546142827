import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Eye, Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';

const ConsultantJobDescription = () => {
  const [job, setJob] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Job Description',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    checkAuthStatus();
    fetchJobDetails();
    fetchUser();
    fetchUnreadNotifications();
  }, [jobId]);

  const checkAuthStatus = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    setIsAuthenticated(!!session);
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const fetchJobDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select(`
          *,
          clients (name),
          jobs_epic_certifications (
            epic_applications (name)
          )
        `)
        .eq('job_id', jobId)
        .eq('status', 'Active')
        .single();

      if (error) throw error;

      if (data.status !== 'Active') {
        setError('This job is no longer active.');
      } else {
        setJob(data);
      }

      // Log view in Firebase Analytics
      const analytics = getAnalytics();
      logEvent(analytics, 'job_view', {
        job_id: jobId,
        job_title: data.title,
        is_authenticated: isAuthenticated
      });

      // Log view in Supabase for rate limiting
      await supabase.from('job_views').insert({
        job_id: jobId,
        user_id: isAuthenticated ? (await supabase.auth.getUser()).data.user.id : null,
        viewed_at: new Date()
      });

    } catch (error) {
      console.error('Error fetching job details:', error.message);
      setError('Failed to load job details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const showAuthRedirectPopup = () => {
    alert("You need to create an account to perform this action. Redirecting you to sign up.");
  };

  const handleAuthAction = async (action) => {
    if (!isAuthenticated) {
      showAuthRedirectPopup();
      navigate('/auth', { state: { from: `/job/${jobId}`, action } });
    } else {
      if (action === 'apply') {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) throw new Error("No authenticated user found");

          const { data, error } = await supabase
            .from('user_jobs')
            .upsert({
              user_id: user.id,
              job_id: jobId,
              status: 'Application in Progress',
              created_at: new Date().toISOString()
            }, {
              onConflict: 'user_id,job_id',
              returning: 'minimal'
            });

          if (error) throw error;

          navigate(`/apply-job/${jobId}`);
        } catch (error) {
          console.error('Error initiating application:', error.message);
          // Handle error (show error message to user)
        }
      } else if (action === 'refer') {
        navigate(`/refer-job/${jobId}`);
      }
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getTimeSincePosted = (postedDate) => {
    const now = new Date();
    const posted = new Date(postedDate);
    const diffInMilliseconds = now - posted;
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  
    if (diffInDays === 0) {
      return "Posted today";
    } else if (diffInDays === 1) {
      return "Posted yesterday";
    } else {
      return `Posted ${diffInDays} days ago`;
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!job) return <div>No job found</div>;

  return (
    <>
      <Helmet>
        <title>{`${job.title} | fetchConsultant`}</title>
        <meta name="description" content={`View details and apply for the ${job.title} position at ${isAuthenticated ? job.clients.name : 'a leading healthcare organization'}.`} />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="job-description" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">{job.title}</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card bg-white rounded-lg shadow-md p-6">
            {isAuthenticated ? (
              <h2 className="text-2xl font-bold text-left">{job.clients.name}</h2>
            ) : (
              <button 
                onClick={() => handleAuthAction('viewClient')}
                className="text-indigo-600 hover:text-indigo-800 flex items-center"
              >
                <Eye size={20} className="mr-2" />
                Show Client's Name
              </button>
            )}
            <div className="mt-1">
              <h3 className="visually-hidden">Job Description</h3> {/* Hidden but SEO-friendly */}
              <div 
                className="job-description" 
                dangerouslySetInnerHTML={{ __html: job.description }}
              ></div>
            </div>
            <div className="space-y-6 text-left">
              <div>
                <p className="font-bold text-gray-700">Ideal Start Date</p>
                <p>{formatDate(job.ideal_start_date)}</p>
              </div>
              <div>
                <p className="font-bold text-gray-700">Onsite or Remote</p>
                <p>{job.travel === '100% Remote' ? 'Remote' : job.travel}</p>
              </div>
              {job.travel !== '100% Remote' && (
                <div>
                  <p className="font-bold text-gray-700">City/State</p>
                  <p>{`${job.city}, ${job.state}`}</p>
                </div>
              )}
              <div>
                <p className="font-bold text-gray-700">Duration</p>
                <p>{job.duration}</p>
              </div>
              <div>
                <p className="font-bold text-gray-700">Extension Probability</p>
                <p>{job.extension_possibility}</p>
              </div>
              <div>
                <p className="font-bold text-gray-700">Required Certifications</p>
                <ul className="list-disc list-inside ml-4">
                  {job.jobs_epic_certifications.map((cert, index) => (
                    <li key={index}>{cert.epic_applications.name}</li>
                  ))}
                </ul>
              </div>
              <div>
                <p className="font-bold text-gray-700">Minimum Experience</p>
                <div 
                  className="job-description" 
                  dangerouslySetInnerHTML={{ __html: job.experience }}
                ></div>
              </div>
              <div>
                <p className="font-bold text-gray-700">Education Requirement</p>
                <p>{job.education}</p>
              </div>
              <div>
                <p className="font-bold text-gray-700">Referral Bonus</p>
                <p>{job.referral_bonus}</p>
              </div>
              <div>
                <p className="font-bold text-gray-700">Created Date</p>
                <p>{formatDate(job.created_at)}</p>
              </div>
              <div>
                <p className="font-bold text-gray-700">Status</p>
                <p>{job.status}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 text-left mb-4">{getTimeSincePosted(job.posted_at)}</p>
              </div>
            </div>
  
            <div className="mt-8 flex justify-between">
              <button 
                onClick={() => handleAuthAction('apply')}
                className="fetch-button w-5/12 bg-indigo-600 text-white"
              >
                Apply
              </button>
              <button 
                onClick={() => handleAuthAction('refer')}
                className="fetch-button w-5/12 bg-indigo-100 text-indigo-600 hover:bg-indigo-200"
              >
                Refer
              </button>
            </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ConsultantJobDescription;