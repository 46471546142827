import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, ChevronLeft, Menu, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';

const ApplyJob = () => {
  const [videoQuestions, setVideoQuestions] = useState([]);
  const [videoResponses, setVideoResponses] = useState({});
  const [fileNames, setFileNames] = useState({});
  const [formData, setFormData] = useState({
    consultant_rate: '',
    available_start_date: '',
    consultant_notes: '',
    terms_and_conditions: false
  });
  const [formValid, setFormValid] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Apply for Job',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobDetails();
    loadFormData();
    fetchUnreadNotifications();
    fetchUser();
  }, [jobId]);

  useEffect(() => {
    const isValid = 
      formData.consultant_rate.trim() !== '' &&
      formData.available_start_date !== '' &&
      formData.terms_and_conditions;
    setFormValid(isValid);
  
    // Save form data, videoResponses, and fileNames to localStorage
    localStorage.setItem(`applyJobFormData_${jobId}`, JSON.stringify(formData));
    localStorage.setItem(`applyJobVideoResponses_${jobId}`, JSON.stringify(videoResponses));
    localStorage.setItem(`applyJobFileNames_${jobId}`, JSON.stringify(fileNames));
  }, [formData, videoResponses, fileNames, jobId]);
  
  const fetchJobDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('title, video_questions')
        .eq('job_id', jobId)
        .single();

      if (error) throw error;
      setJob(data);
      setVideoQuestions(data.video_questions || []);
    } catch (error) {
      console.error('Error fetching job details:', error.message);
    }
  };

  const loadFormData = () => {
    const savedFormData = localStorage.getItem(`applyJobFormData_${jobId}`);
    const savedVideoResponses = localStorage.getItem(`applyJobVideoResponses_${jobId}`);
    const savedFileNames = localStorage.getItem(`applyJobFileNames_${jobId}`);
  
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  
    if (savedVideoResponses) {
      setVideoResponses(JSON.parse(savedVideoResponses));
    }
  
    if (savedFileNames) {
      setFileNames(JSON.parse(savedFileNames));
    }
  
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('termsAccepted') === 'true') {
      setFormData(prevState => ({
        ...prevState,
        terms_and_conditions: true
      }));
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleRichTextChange = (content) => {
    setFormData(prevState => ({
      ...prevState,
      consultant_notes: content
    }));
  };

  const [videoUploadsInProgress, setVideoUploadsInProgress] = useState({}); // To track video upload status

  const handleVideoUpload = async (e, questionIndex) => {
    const file = e.target.files[0];
  
    if (file.size > 50 * 1024 * 1024) {
      alert("File size must be less than 50MB");
      return;
    }
    if (!file.type.startsWith("video/")) {
      alert("Only video files are allowed");
      return;
    }
  
    try {
      // Mark the upload as in progress
      setVideoUploadsInProgress(prev => ({ ...prev, [questionIndex]: true }));
  
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExt}`;
      const filePath = `video_responses/${jobId}/${fileName}`; // Ensure correct path
  
      const { data, error } = await supabase.storage
        .from('video_responses')
        .upload(filePath, file);
  
      if (error) throw error;
  
      const { publicUrl, error: urlError } = supabase.storage
        .from('video_responses')
        .getPublicUrl(filePath);
  
      if (urlError) throw urlError;
  
      // Update state once the video is uploaded
      setVideoResponses(prev => ({
        ...prev,
        [questionIndex]: filePath
      }));
  
      setFileNames(prev => ({
        ...prev,
        [questionIndex]: file.name
      }));
  
      // Mark the upload as complete
      setVideoUploadsInProgress(prev => ({ ...prev, [questionIndex]: false }));
  
    } catch (error) {
      console.error('Error uploading video:', error);
      alert(`Error uploading video: ${error.message}`);
    }
  };
  
  // Check if any video uploads are still in progress before submitting
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formValid) return;
  
    // Prevent form submission if any uploads are still in progress
    if (Object.values(videoUploadsInProgress).some(upload => upload)) {
      alert("Please wait for all videos to finish uploading before proceeding.");
      return;
    }
  
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const videoResponsesObject = {};
      videoQuestions.forEach((question, index) => {
        if (videoResponses[index]) {
          videoResponsesObject[index] = videoResponses[index];
        }
      });
  
      console.log('Submitting video responses:', videoResponsesObject);
  
      const { data, error } = await supabase
        .from('user_jobs')
        .upsert({
          user_id: user.id,
          job_id: jobId,
          status: 'Application in Progress',
          consultant_rate: formData.consultant_rate,
          available_start_date: formData.available_start_date,
          consultant_notes: formData.consultant_notes,
          terms_and_conditions: formData.terms_and_conditions,
          video_responses: videoResponsesObject
        }, {
          onConflict: 'user_id,job_id',
          returning: 'representation'
        });
  
      if (error) throw error;
  
      console.log('Application submitted successfully:', data);
  
      localStorage.removeItem(`applyJobFormData_${jobId}`);
      navigate(`/apply-job-interview/${jobId}`);
    } catch (error) {
      console.error('Error submitting application:', error.message);
      alert(`Error submitting application: ${error.message}`);
    }
  };

  const handleTermsAndConditions = () => {
    navigate(`/terms-and-conditions/${jobId}?returnUrl=${encodeURIComponent(`/apply-job/${jobId}`)}`);
  };

  return (
    <>
      <Helmet>
        <title>Apply for Job | fetchConsultant</title>
        <meta name="description" content="Apply for an Epic consulting position on fetchConsultant." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Apply for Job | fetchConsultant" />
        <meta property="og:description" content="Submit your hourly rate directly to the client." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.fetchconsultant.com/apply-job/:jobId" />
        <meta property="og:image" content="https://app.fetchconsultant.com/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="apply-job" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Apply for Job</h1>
            </div>
            <button onClick={() => navigate('/notifications')} className="relative">
              <Bell size={24} />
              {unreadNotifications > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {unreadNotifications}
                </span>
              )}
            </button>
          </header>
          <main className="fetch-container">
            <div className="fetch-progress">
              <p className="fetch-secondary-text">Step 1 of 2</p>
              <div className="fetch-progress-bar">
                <div className="fetch-progress-bar-fill" style={{width: '50%'}}></div>
              </div>
            </div>
            <div className="fetch-card">
              <form onSubmit={handleSubmit} className="fetch-form">
              <div>
                <label htmlFor="consultant_rate" className="fetch-label">My Hourly Rate<span className="fetch-required">*</span></label>
                <input
                  type="number"
                  id="consultant_rate"
                  name="consultant_rate"
                  value={formData.consultant_rate}
                  onChange={handleInputChange}
                  className="fetch-input"
                  placeholder="$100"
                  required
                />
              </div>

              <div>
                <label htmlFor="available_start_date" className="fetch-label">Available Start Date<span className="fetch-required">*</span></label>
                <div className="relative">
                  <input
                    type="date"
                    id="available_start_date"
                    name="available_start_date"
                    value={formData.available_start_date}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="consultant_notes" className="fetch-label">Consultant Notes for Client</label>
                <ReactQuill
                  theme="snow"
                  value={formData.consultant_notes}
                  onChange={handleRichTextChange}
                  className="fetch-input"
                  modules={{
                      toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{'list': 'ordered'}, {'list': 'bullet'}],
                      ['link'],
                      ],
                  }}
                />
              </div>

              {videoQuestions.map((question, index) => (
                <div key={index} className="mb-4">
                  <label className="fetch-label">{question}</label>
                  <div className="mt-1 flex items-center">
                    <input
                      type="file"
                      accept="video/*"
                      onChange={(e) => handleVideoUpload(e, index)}
                      className="hidden"
                      id={`video-upload-${index}`}
                    />
                    <label
                      htmlFor={`video-upload-${index}`}
                      className="file-input-button"
                    >
                      <Upload className="w-5 h-5 mr-2" />
                      {fileNames[index] ? fileNames[index] : 'Upload Response'}
                    </label>
                    {videoResponses[index] && (
                      <span className="ml-3 text-sm text-gray-600">
                        Video uploaded successfully
                      </span>
                    )}
                  </div>
                  <p className="text-sm text-gray-500">Max size: 50MB, only video files are allowed.</p>
                </div>
              ))}

              <div className="mt-6">
                <h2 className="text-xl font-bold mb-2">Terms and Conditions</h2>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="terms_and_conditions"
                    name="terms_and_conditions"
                    checked={formData.terms_and_conditions}
                    onChange={handleInputChange}
                    className="fetch-checkbox"
                    disabled={true}
                    required
                  />
                  <label htmlFor="terms_and_conditions" className="fetch-checkbox-label">
                    I have read and agree to the <button type="button" onClick={handleTermsAndConditions} className="text-indigo-600 hover:text-indigo-800">terms and conditions</button>.<span className="fetch-required">*</span>
                  </label>
                </div>
              </div>
              <div className="mt-6">
              <button
                type="submit"
                className={`fetch-button ${!formValid || Object.values(videoUploadsInProgress).some(upload => upload) ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!formValid || Object.values(videoUploadsInProgress).some(upload => upload)}
              >
                Next
              </button>
              </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplyJob;