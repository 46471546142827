import { getAnalytics, logEvent } from "firebase/analytics";
import debounce from 'lodash/debounce';
import { Bell, Menu, Plus, Trash2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';

const AddJob = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobStatus, setJobStatus] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [formData, setFormData] = useState({
    job_id: jobId || '', // Use existing jobId or empty string for new jobs
    client_id: '',
    title: '',
    description: '',
    ideal_start_date: '',
    duration: '',
    extension_possibility: '',
    travel: '',
    city: '',
    state: '',
    experience: '',
    education: '',
    referral_bonus: '',
    status: 'Draft',
    terms_and_conditions: false
  });
  const [epicApplications, setEpicApplications] = useState([]);
  const [selectedEpicCertifications, setSelectedEpicCertifications] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: jobId ? 'Edit Job' : 'Add Job',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserEmployer();
    fetchEpicApplications();
    fetchUnreadNotifications();

    const initializeJob = async () => {
      if (jobId) {
        fetchJobDetails();
        const { data, error } = await supabase
          .from('jobs')
          .select('status, video_questions')
          .eq('job_id', jobId)
          .single();

        if (error) {
          console.error('Error fetching job status:', error);
        } else {
          setJobStatus(data.status);
          setQuestions(data.video_questions || []);
        }
      } else {
        setJobStatus(null);
        setFormData({
          job_id: '',
          client_id: '',
          title: '',
          description: '',
          // ... other fields
        });
        setQuestions([]);
      }

      logEvent(analytics, 'page_view', {
        page_title: jobId && jobStatus !== 'Draft' ? 'Edit Job' : 'Post a New Contract',
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    };

    initializeJob();
  }, [jobId]);

  const fetchUserEmployer = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setUser(data);
      setFormData(prevData => ({
        ...prevData,
        client_id: data.employer
      }));
    } catch (error) {
      console.error('Error fetching user employer:', error);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { count, error } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      setUnreadNotifications(count);
    } catch (error) {
      console.error('Error fetching unread notifications:', error.message);
    }
  };

  const fetchEpicApplications = async () => {
    const { data, error } = await supabase.from('epic_applications').select('epic_application_id, name');
    if (error) console.error('Error fetching Epic applications:', error);
    else setEpicApplications(data);
  };

  const fetchJobDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('*')
        .eq('job_id', jobId)
        .single();

      if (error) throw error;

      setFormData(data);
      setJobStatus(data.status);

      const { data: certifications, error: certError } = await supabase
        .from('jobs_epic_certifications')
        .select('epic_application_id')
        .eq('job_id', jobId);

      if (certError) throw certError;

      setSelectedEpicCertifications(certifications.map(c => c.epic_application_id));
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (name === 'title') {
      debouncedSaveTitle(value);
    }
  };

  const handleRichTextChange = (content, editor) => {
    const name = editor.getEditor().container.dataset.name;
    setFormData(prevState => ({
      ...prevState,
      [name]: content
    }));
  };

  const handleEpicCertificationChange = (e) => {
    const value = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedEpicCertifications(value);
    debouncedSaveEpicCertifications(value);
  };

  const debouncedSaveTitle = debounce(async (title) => {
    try {
      const { error } = await supabase
        .from('jobs')
        .update({ title, last_updated_at: new Date().toISOString() })
        .eq('job_id', formData.job_id);

      if (error) throw error;
    } catch (error) {
      console.error('Error saving job title:', error);
    }
  }, 500);

  const debouncedSaveEpicCertifications = debounce(async (certifications) => {
    try {
      // First, fetch existing certifications
      const { data: existingCerts, error: fetchError } = await supabase
        .from('jobs_epic_certifications')
        .select('epic_application_id')
        .eq('job_id', formData.job_id);

      if (fetchError) throw fetchError;

      // Determine which certifications to add and which to remove
      const existingCertIds = existingCerts.map(cert => cert.epic_application_id);
      const certsToAdd = certifications.filter(certId => !existingCertIds.includes(certId));
      const certsToRemove = existingCertIds.filter(certId => !certifications.includes(certId));

      // Remove certifications that are no longer selected
      if (certsToRemove.length > 0) {
        const { error: removeError } = await supabase
          .from('jobs_epic_certifications')
          .delete()
          .eq('job_id', formData.job_id)
          .in('epic_application_id', certsToRemove);

        if (removeError) throw removeError;
      }

      // Add new certifications
      if (certsToAdd.length > 0) {
        const { error: addError } = await supabase
          .from('jobs_epic_certifications')
          .insert(certsToAdd.map(certId => ({
            job_id: formData.job_id,
            epic_application_id: certId
          })));

        if (addError) throw addError;
      }
    } catch (error) {
      console.error('Error saving Epic certifications:', error);
    }
  }, 500);

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      const analytics = getAnalytics();
      logEvent(analytics, 'add_job_question', {
        question_count: questions.length + 1
      });
      setQuestions([...questions, newQuestion.trim()]);
      setNewQuestion('');
    }
  };

  const handleRemoveQuestion = (index) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'remove_job_question', {
      question_count: questions.length - 1
    });
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  useEffect(() => {
    const isValid = 
      formData.title.trim() !== '' &&
      formData.description.trim() !== '' &&
      formData.ideal_start_date !== '' &&
      formData.duration !== '' &&
      formData.extension_possibility !== '' &&
      formData.travel !== '' &&
      (formData.travel === '100% Remote' || (formData.city?.trim() !== '' && formData.state !== '')) &&
      formData.experience?.trim() !== '' &&
      formData.education !== '' &&
      formData.referral_bonus !== '' &&
      formData.terms_and_conditions &&
      selectedEpicCertifications.length > 0;

    setFormValid(isValid);
  }, [formData, selectedEpicCertifications]);

  const handleSubmit = async (e, isDraft = false) => {
    e.preventDefault();
    if (!isDraft && !formValid) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      // Capture the current state of questions, including the newQuestion if it's not empty
      const currentQuestions = newQuestion.trim() 
        ? [...questions, newQuestion.trim()]
        : questions;

      console.log('Questions before submission:', currentQuestions);

      const jobData = {
        ...formData,
        status: isDraft ? 'Draft' : 'Active',
        created_by: user.id,
        last_updated_at: new Date().toISOString(),
        video_questions: currentQuestions
      };

      if (!jobData.job_id) {
        jobData.job_id = uuidv4();
        jobData.created_at = new Date().toISOString();
        jobData.posted_at = jobData.status === 'Active' ? new Date().toISOString() : null;
      } else if (jobData.status === 'Active' && formData.status === 'Draft') {
                // If changing from Draft to Active, set posted_at
        jobData.posted_at = new Date().toISOString();
      }

            // Remove city and state if travel is 100% Remote
      if (jobData.travel === '100% Remote') {
        delete jobData.city;
        delete jobData.state;
      }

      console.log('Saving questions:', questions);

            // Upsert the job
      const { data, error: jobError } = await supabase
        .from('jobs')
        .upsert([jobData])
        .select()
        .single();

      setJobStatus(jobData.status);

      if (jobError) throw jobError;

      console.log('Saved job data:', data);

      if (!isDraft) {
        await debouncedSaveEpicCertifications(selectedEpicCertifications);
      }

      alert(isDraft ? 'Draft saved successfully!' : 'Job posted successfully!');
      navigate('/client-dashboard');
    } catch (error) {
      console.error('Error saving job:', error);
      alert(`Error saving job: ${error.message}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {jobId && jobStatus !== 'Draft' ? 'Update Contract' : 'Post Contract'} | fetchConsultant
        </title>
        <meta name="description" content={jobId && jobStatus !== 'Draft'
          ? "Update an existing contract opportunity for Epic consultants on fetchConsultant."
          : "Post a contract opportunity for Epic consultants on fetchConsultant. Find the best healthcare IT talent for your organization."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Client Onboarding - Personal Information | fetchConsultant" />
        <meta property="og:description" content="Complete your personal profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.fetchconsultant.com/client-onboarding-personal" />
        <meta property="og:image" content="https://app.fetchconsultant.com/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="addjob" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">
                {jobId && jobStatus !== 'Draft' ? 'Update Contract' : 'Post Contract'}
              </h1>
            </div>
            <button onClick={() => navigate('/notifications')} className="relative">
              <Bell size={24} />
              {unreadNotifications > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {unreadNotifications}
                </span>
              )}
            </button>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
              <form onSubmit={(e) => handleSubmit(e, false)} className="fetch-form">
            <div>
                <label htmlFor="title" className="fetch-label">Job Title<span className="fetch-required">*</span></label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="fetch-input"
                  placeholder="e.g. Ambulatory PM"
                  required
                />
              </div>

              <div>
                <label htmlFor="description" className="fetch-label">Job Description<span className="fetch-required">*</span></label>
                <ReactQuill
                  theme="snow"
                  value={formData.description}
                  onChange={(content) => handleRichTextChange(content, { getEditor: () => ({ container: { dataset: { name: 'description' } } }) })}
                  rows="4"
                  className="fetch-input tall-editor"
                  data-name="description"
                />
              </div>

              <div>
                <label htmlFor="ideal_start_date" className="fetch-label">Ideal Start Date<span className="fetch-required">*</span></label>
                <div className="relative">
                  <input
                    type="date"
                    name="ideal_start_date"
                    id="ideal_start_date"
                    value={formData.ideal_start_date}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="duration" className="fetch-label">Duration<span className="fetch-required">*</span></label>
                <select
                  id="duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select duration</option>
                  <option value="3 Months">3 Months</option>
                  <option value="6 Months">6 Months</option>
                  <option value="12 Months">12 Months</option>
                </select>
              </div>

              <div>
                <label htmlFor="extension_possibility" className="fetch-label">Extension Possibility<span className="fetch-required">*</span></label>
                <select
                  id="extension_possibility"
                  name="extension_possibility"
                  value={formData.extension_possibility}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select possibility</option>
                  <option value="Likely">Likely</option>
                  <option value="Not Likely">Not Likely</option>
                </select>
              </div>

              <div>
                <label htmlFor="travel" className="fetch-label">Travel Requirement<span className="fetch-required">*</span></label>
                <select
                  id="travel"
                  name="travel"
                  value={formData.travel}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select requirement</option>
                  <option value="100% Remote">100% Remote</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="100% Onsite">100% Onsite</option>
                </select>
              </div>

            {formData.travel !== '100% Remote' && (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="city" className="fetch-label">City<span className="fetch-required">*</span></label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="state" className="fetch-label">State<span className="fetch-required">*</span></label>
                  <select
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="fetch-select"
                    required
                  >
                    <option value="">Select state</option>
                    {/* Add options for all states */}
                    <option value="TX">TX</option>
                    {/* ... other states ... */}
                  </select>
                </div>
              </div>
            )}

              <div>
                <label htmlFor="epic_certifications" className="fetch-label">Epic Certification(s) Required<span className="fetch-required">*</span></label>
                <select
                  multiple
                  id="epic_certifications"
                  name="epic_certifications"
                  value={selectedEpicCertifications}
                  onChange={handleEpicCertificationChange}
                  className="fetch-multiselect"
                  required
                >
                  {epicApplications.map(app => (
                    <option key={app.epic_application_id} value={app.epic_application_id}>{app.name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="experience" className="fetch-label">Minimum Experience<span className="fetch-required">*</span></label>
                <ReactQuill
                  theme="snow"
                  value={formData.experience}
                  onChange={(content) => handleRichTextChange(content, { getEditor: () => ({ container: { dataset: { name: 'experience' } } }) })}
                  className="fetch-input tall-editor"
                  data-name="experience"
                />
              </div>

              <div>
                <label htmlFor="education" className="fetch-label">Education Required<span className="fetch-required">*</span></label>
                <select
                  id="education"
                  name="education"
                  value={formData.education}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select education</option>
                  <option value="Bachelor's Degree">Bachelor's Degree</option>
                  <option value="Master's Degree">Master's Degree</option>
                  <option value="None">None</option>
                </select>
              </div>

              <div>
                <label htmlFor="referral_bonus" className="fetch-label">Referral Bonus<span className="fetch-required">*</span></label>
                <select
                  id="referral_bonus"
                  name="referral_bonus"
                  value={formData.referral_bonus}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select bonus</option>
                  <option value="$1,000">$1,000</option>
                  <option value="$2,000">$2,000</option>
                  <option value="$3,000">$3,000</option>
                </select>
              </div>

              <div className="mb-6">
                <h3 className="fetch-label mb-2">Video Response Questions</h3>
                {questions.map((question, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={question}
                      onChange={(e) => handleQuestionChange(index, e.target.value)}
                      className="fetch-input flex-grow mr-2"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveQuestion(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                ))}
                <div className="flex items-center">
                  <input
                    type="text"
                    value={newQuestion}
                    onChange={(e) => setNewQuestion(e.target.value)}
                    placeholder="Enter a new question"
                    className="fetch-input flex-grow mr-2"
                  />
                  <button
                    type="button"
                    onClick={handleAddQuestion}
                    className="bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700"
                  >
                    <Plus size={20} />
                  </button>
                </div>
              </div>

              {/* ... (keep existing form fields and submit buttons) */}
              <div className="flex items-center">
                <input
                  id="terms_and_conditions"
                  name="terms_and_conditions"
                  type="checkbox"
                  checked={formData.terms_and_conditions}
                  onChange={handleInputChange}
                  className="fetch-checkbox"
                  required
                />
                <label htmlFor="terms_and_conditions" className="fetch-checkbox-label">
                  I have read and agree to the terms and conditions.<span className="fetch-required">*</span>
                </label>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={(e) => handleSubmit(e, true)}
                  className="fetch-button bg-gray-500 hover:bg-gray-600"
                >
                  Save Draft
                </button>
                <button
                  type="submit"
                  className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!formValid}
                >
                  {jobId && jobStatus !== 'Draft' ? 'Update Contract' : 'Post Contract'}
                </button>
              </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default AddJob;